import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CONFIG from '../config';
import { handleHttpError } from '../utils/fetchUtils';


const initialState = {
  isAuthenticated: localStorage.getItem('loggedIn') === '1' ? true : false,
  error: null
};

export const login = createAsyncThunk(
  'authentication/login',
  async (payload) => {
    const { username, password } = payload;
    const formBody = { username, password };
    await fetch(`${CONFIG.serverUrl}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      credentials: CONFIG.auth.credentialsMode,
      body: Object.keys(formBody)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formBody[key])}`)
        .join('&'),
    })
      .then(async r => {
        if (!r.ok) {
          throw new Error(await r.text());
        }
      });
    return;
  });

export const loginWithGoogle = createAsyncThunk(
  'authentication/login/google',
  async (idToken) => {
    await fetch(`${CONFIG.serverUrl}/auth/login/google`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: CONFIG.auth.credentialsMode,
      body: JSON.stringify(idToken)
    })
      .then(async r => {
        if (!r.ok) {
          throw new Error(await r.text());
        }
      });
    return;
  });

export const signup = createAsyncThunk(
  'authentication/signup',
  async (payload) => {
    const response = await fetch(`${CONFIG.serverUrl}/account/signup`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(handleHttpError);
    return response;
  }
);

export const signupWithGoogle = createAsyncThunk(
  'authentication/signup/google',
  async (payload) => {
    await fetch(`${CONFIG.serverUrl}/account/signup/google`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: CONFIG.auth.credentialsMode,
      body: JSON.stringify(payload),
    })
      .then(async r => {
        if (!r.ok) {
          throw new Error(await r.text());
        }
      });
    return;
  }
);

export const deleteAccount = createAsyncThunk(
  'authentication/delete',
  async (payload) => {
    const response = await fetch(`${CONFIG.serverUrl}/account/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: CONFIG.auth.credentialsMode
    })
      .then(async () => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
        return true;
      });
    return response;
  }
);

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: (state) => {
      console.debug('Logging out');
      state.isAuthenticated = false;
      localStorage.setItem('loggedIn', 0);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state) => {
        console.debug('Login successful');
        state.isAuthenticated = true;
        localStorage.setItem('loggedIn', 1);
      })
      .addCase(login.rejected, (state, action) => {
        console.debug('Login failed:', action.error.message);
        state.isAuthenticated = false;
        state.error = action.error.message;
      })
      .addCase(loginWithGoogle.fulfilled, (state) => {
        console.debug('Login successful');
        state.isAuthenticated = true;
        localStorage.setItem('loggedIn', 1);
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        console.debug('Login failed:', action.error.message);
        state.isAuthenticated = false;
        state.error = action.error.message;
      })
      .addCase(signup.fulfilled, () => {
        console.debug('Signup succeeded');
      })
      .addCase(signup.rejected, (state, action) => {
        console.error('Signup failed', action.error.message);
        state.error = action.error.message;
      })
      .addCase(signupWithGoogle.fulfilled, (state) => {
        console.debug('Signup with google succeeded');
        state.isAuthenticated = true;
        localStorage.setItem('loggedIn', 1);
      })
      .addCase(signupWithGoogle.rejected, (state, action) => {
        console.error('Signup with google failed', action.error.message);
        state.isAuthenticated = false;
        state.error = action.error.message;
      })
      .addCase(deleteAccount.fulfilled, () => {
        console.debug('Deleted account succeeded');
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        console.error('Delete account failed', action.error.message);
        state.error = action.error.message;
      });
  }
});


export const { logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
