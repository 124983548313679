import styles from './howto.module.css';
import broadcastNotStartetImage from './images/Audiorecorder-broadcast-notstarted.png';
import broadcastStartetImage from './images/Audiorecorder-broadcast-started.png';
import broadcastSettingsTabImage from './images/settings-broadcast-tab.png';
import broadcastSettingsImage from './images/settings-broadcast-tab-server-settings.png';
import { useEffect } from 'react';


const TraktorHowTo = () => {
  useEffect(() => {
    document.title = 'How to broadcast with Traktor Pro for free on Onda the free live streaming platform for DJs';
  }, []);

  return (
    <div className={`${styles.HowToPage} infoPage`}>
      <h1>How to broadcast with Traktor Pro for free</h1>
      <div className={styles.Headline}>
        A step-by-step-tutorial for streaming on onda
      </div>
      <div className={styles.StepList}>
        <div className={styles.Step}>
          <div className={styles.Index}>1.</div>
          <div className={styles.Content}>
            <div className={styles.Text}>
              Open your dj software. In this howto the plattform Traktor is an
              example for other softwares.
            </div>
          </div>
        </div>
        <div className={styles.Step}>
          <div className={styles.Index}>2.</div>
          <div className={styles.Content}>
            <div className={styles.Text}>
              To broadcast from Traktor, go to the second FX unit and select the
              symbol beneath ‘FX’. You will now be in the Audio Recorder. Simply
              click the antenna symbol, which will turn blue if it is connected
              to a stream, or blink if there’s no connection.
            </div>
            <img alt="broadcast not started" src={broadcastNotStartetImage} />
            <img alt="broadcast started" src={broadcastStartetImage} />
          </div>
        </div>
        <div className={styles.Step}>
          <div className={styles.Index}>3.</div>
          <div className={styles.Content}>
            <div className={styles.Text}>
              To set up Traktor to broadcast through the “onda” app, click on the
              cogwheel symbol or enter ‘Settings’. Than go into ‘Broadcasting’.
            </div>
            <img alt="broadcast settings tab" src={broadcastSettingsTabImage} />
          </div>
        </div>
        <div className={styles.Step}>
          <div className={styles.Index}>4.</div>
          <div className={styles.Content}>
            <div className={styles.Text}>
              Here, you will find the tabs “Server Settings” and “Metadata
              Settings”. “Server Settings” is where you enter your userdata,
              that you can find in your BASSS account at “Start Stream”. Enter
              your personalized given userdata, meaning the adress, port, mount
              path and password into the form.
            </div>
            <img alt="broadcast settings" src={broadcastSettingsImage} />

          </div>
        </div>
        <div className={styles.Step}>
          <div className={styles.Index}>5.</div>
          <div className={styles.Content}>
            <div className={styles.Text}>
              “Metadata Settings” is where you can personalize  your stream by giving
              it a name, description and genre. Try giving your stream an authentic
              genre title. These settings will be shown on your onda stream.
            </div>
          </div>
        </div>
        <div className={styles.Step}>
          <div className={styles.Index}>6.</div>
          <div className={styles.Content}>
            <div className={styles.Text}>
              After finishing your Metadata & Server Settings configuration exit the
              Traktor Setting Panel. Enter the Audio Recorder again and click the
              antenna to begin your broadcast. The symbol should then light up blue.
              If you experience any connection issues, check your broadcasting
              information is correct or check your internet connection.
              If you want to stop your stream, simply click the antenna symbol, which
              will turn grey again when your offline.
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default TraktorHowTo;
