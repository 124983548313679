
const VirtualDJHowTo = () => {
  return (
    <div>
      <h1>How to broadcast with VirtualDJ for free</h1>
    </div>
  );
};

export default VirtualDJHowTo;
