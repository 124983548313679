import { useDispatch } from 'react-redux';
import { logout } from './authenticationSlice';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../utils/DialogContext';
import { ToastTypes, useToast } from '../toast/ToastContext';


const LogoutButton = ({action}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openToast = useToast();
  const dialog = useDialog();

  const logoutUser = () => {
    if (action) {
      action();
    }
    openToast({
      title: 'Bye bye!',
      message: 'Hope to see you back soon :)',
      type: ToastTypes.SUCCESS
    });
    dispatch(logout());
    navigate('/');
  };

  return (
    <>
      <a onClick={() => dialog({
          title: `Logout?`,
          action: {
            confirmText: 'Logout',
            callback: logoutUser
          },
          content: `Are you sure you want to logout?`
        })}>
        Logout
      </a>
    </>
  );
};

export default LogoutButton;
