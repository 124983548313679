
const SeratoHowTo = () => {
  return (
    <div>
      <h1>How to broadcast with Serato for free</h1>
    </div>
  );
};

export default SeratoHowTo;
