import { useState } from 'react';
import config from '../config';
import { ToastTypes, useToast } from '../toast/ToastContext';
import InputErrorMessage from '../utils/InputErrorMessage';

const ChangePasswordForm = ({onSuccess}) => {
  const [changePasswordDTO, setChangePasswordDTO] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  });
  const [error, setError] = useState(null);
  const openToast = useToast();

  const handleChange = ({target}) => {
    if (error) {
      setError(null);
    }
    const { name, value } = target;
    setChangePasswordDTO({ ...changePasswordDTO, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (changePasswordDTO.newPassword !== changePasswordDTO.newPasswordConfirmation) {
      setError('New passwords do not match');
      return;
    }
    fetch(`${config.serverUrl}/account/password/change`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: config.auth.credentialsMode,
      body: JSON.stringify(changePasswordDTO)
    })
      .then(async (response) => {
        if (response.ok) {
          openToast({
            title: 'Password changed',
            message: 'Your password has been changed successfully',
            type: ToastTypes.SUCCESS
          });
          return onSuccess();
        }
        const errorMessage = await response.text();
        setError(errorMessage);
      }
      );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="currentPassword">Current Password</label>
        <input type="password" name="currentPassword" placeholder="Current Password" onChange={handleChange}/>
        <label htmlFor="newPassword">New Password</label>
        <input type="password" name="newPassword" placeholder="New Password" onChange={handleChange}/>
        <label htmlFor="newPasswordConfirmation">Confirm New Password</label>
        <input type="password" name="newPasswordConfirmation" placeholder="Confirm New Password" onChange={handleChange}/>
        <InputErrorMessage shouldRender={error !== null} message={error}/>
        <button type="submit">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
