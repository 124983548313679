import { useDispatch, useSelector } from 'react-redux';
import { subscribeToUser, unsubscribeFromUser } from './pushNotificationSlice';
import { get } from '../user/userSlice';

import styles from './pushnotifications.module.css';

import { ReactComponent as SubscribeIcon } from '../icons/Subscribe-Icon.svg';
import { ReactComponent as UnsubscribeIcon } from '../icons/Subscribed-Icon.svg';
import { useDialog } from '../utils/DialogContext';
import { ToastTypes, useToast } from '../toast/ToastContext';


const SubscribeButton = ({ user, color }) => {
  const dispatch = useDispatch();
  const subscribedUsers = useSelector(state => state.pushNotification.subscribedUsers);
  const openToast = useToast();
  const dialog = useDialog();

  const subscribe = () => {
    dispatch(subscribeToUser(user.uuid))
      .then(() => {
        openToast({
          title: `Subscribed to ${user.username}!`,
          message: `You will now receive notifications when ${user.username} goes live.`,
          type: ToastTypes.SUCCESS
        });
        updateUser();
      });
  };

  const unsubscribe = () => {
    dispatch(unsubscribeFromUser(user.uuid))
      .then(() => {
        openToast({
          title: `Unsubscribed from ${user.username}!`,
          message: `You will no longer receive notifications when ${user.username} goes live.`,
          type: ToastTypes.SUCCESS
        });
        updateUser();
      });
  };

  const updateUser = () => {
    dispatch(get(user.username));
  };

  return (
    <div className={styles.SubscribeButton}>
      {!subscribedUsers.includes(user?.uuid)
        ? <button className={['small', color].join(' ')} onClick={subscribe}>
          <SubscribeIcon />
          Subscribe
        </button>
        : <button className={['small', 'grey'].join(' ')} onClick={() => dialog({
          title: `Unsubscribe from ${user?.username}?`,
          action: {
            confirmText: 'Unsubscribe',
            callback: unsubscribe
          },
          content: `Are you sure you want to stop getting notifications for ${user?.username}?`
        })}>
          <UnsubscribeIcon />
          Unsubscribe
        </button>
      }
    </div>
  );
};

export default SubscribeButton;
