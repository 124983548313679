import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { search } from './searchSlice';

import { ReactComponent as ClearIcon } from '../icons/Exit-Icon.svg';
import { ReactComponent as BackIcon } from '../icons/Arrow-Icon.svg';
import { ReactComponent as SearchIcon } from '../icons/Search-Icon.svg';

import { useNavigate } from 'react-router-dom';

import styles from './search.module.css';
import Avatar from 'boring-avatars';
import config from '../config';
import { useMediaQuery } from 'react-responsive';

const Search = ({ closeSearch, isOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 900px)' });
  const searchResult = useSelector(state => state.search.searchResult);
  const searchInputElement = useRef(null);
  const searchViewElement = useRef(null);

  const handleSearch = ({ target: { value } }) => {
    dispatch(search(value));
  };

  const clearSearch = (e) => {
    searchInputElement.current.value = '';
  };

  const viewProfile = (username) => {
    setIsFocused(false);
    !isLargeScreen && closeSearch();
    navigate(`user/${username}`, {
      replace: true
    });
  };

  useEffect(() => {
    console.debug(searchResult);
  }, [searchResult]);

  useEffect(() => {
    if (isOpen) {
      searchInputElement.current.focus();
    }
  }, [isOpen]);


  const createCloseListener = () => setTimeout(
    () => window.document.addEventListener('click',
      (e) => {
        if (!searchViewElement.current.contains(e.target)) {
          setIsFocused(false);
        } else {
          createCloseListener();
        }
      }, { once: true }), 0);

  return (
    <div ref={searchViewElement} className={styles.SearchView} >

      <div className={styles.InputWrapper}>
        <div className={[styles.IconWrapper, 'action'].join(' ')}>
          {isLargeScreen
            ? <>
              <div></div>
              <SearchIcon className='searchIcon action' />
            </>
            : <>
              <BackIcon className={!isOpen ? styles.Closed : ''} onClick={closeSearch} />
              <ClearIcon className={!isOpen ? styles.Closed : ''} onClick={clearSearch} />
            </>}
        </div>
        <input className={styles.SearchInput}
          aria-label="Search"
          ref={searchInputElement}
          onChange={handleSearch}
          placeholder="Search..."
          type="text"
          onFocus={e => {
            setIsFocused(true);
            createCloseListener();
          }} />
      </div>

      <div className={[styles.SearchResults, isFocused && styles.Focused].join(' ')}>
        {searchResult.map(user => (
          <div className={styles.SearchResult} onClick={() => viewProfile(user.username)} key={user.username}>
            <div className={styles.AvatarWrapper}>
              {
                user.avatar
                  ? <img className={styles.Avatar} src={user.avatar || user.initialAvatar} alt={user.username} />
                  : <Avatar
                    variant="ring"
                    name={user.uuid}
                    colors={config.initialAvatar.colors}
                  />
              }
            </div>
            <div className={styles.UserDetails}>
              <div className={styles.SearchResultUsername}>{user.username}</div>
              <div> {user.subscriberCount} Subscribers </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Search;
