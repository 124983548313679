import { Link } from 'react-router-dom';
import styles from './NotLoggedInNoBroadcast.module.css';

const NotLoggedInNoBroadcast = () => {
    return (
        <div className={styles.NotLoggedInNoBroadcast}>
            <div className={styles.GradientBlock} />
            <h1 class='broadcast-list' >There are currently no livestreams.</h1>
            <p>
                Try again later or
                <span>
                     <Link to="/login">&nbsp;start your own stream</Link>
                </span>
            </p>
        </div>
    );
};

export default NotLoggedInNoBroadcast;
