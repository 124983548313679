import { useState } from 'react';
import config from '../config';
import InputErrorMessage from '../utils/InputErrorMessage';

import { ReactComponent as SuccessIcon } from '../icons/Check-Symbol.svg';
import { ReactComponent as ErrorIcon } from '../icons/Error-Icon.svg';

import styles from './authentication.module.css';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [requested, setRequested] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setRequested(true);
    fetch(`${config.serverUrl}/account/password/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (response.ok) {
          return setSuccess(true);
        }
        return response.text()
          .then((errorMessage) => {
            setError(errorMessage);
            setSuccess(false);
          });
      });
  };

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
    if (error) {
      setError(null);
    }
  };

  return (
    <div className='infoPage'>
      {!success && (
        <div>
          <h1>Forgot Password?</h1>
          <form className={styles.ForgotPasswordForm} onSubmit={handleSubmit}>
            <label htmlFor='email'>Enter your email address</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
            <InputErrorMessage shouldRender={error !== null} message={error} />
            <button type="submit">
              Reset Password
            </button>
          </form>
        </div>
      )}
      {success && (
        <div className={styles.StatusPage}>
          <SuccessIcon className={styles.SuccessIcon} />
          <h1>Password Reset Requested</h1>
          <p>Check your inbox for further instructions. Do not forget to check your spam folder.</p>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
