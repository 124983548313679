import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from './userSlice';
import Avatar from 'boring-avatars';
import appConfig from '../config.js';
import styles from './user.module.css';
import { ReactComponent as DeleteIcon } from '../icons/Delete-Icon.svg';
import { ReactComponent as UploadIcon } from '../icons/Upload-Icon.svg';
import { ReactComponent as AccountTitle } from '../titles/Account.svg';
import { ReactComponent as BackIcon } from '../icons/Back-Icon-Left.svg';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated, config } from '@react-spring/web';
import { useDialog } from '../utils/DialogContext';
import { ToastTypes, useToast } from '../toast/ToastContext';


const EditProfileForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(state => state.user.current);

  const [profileData, setProfileData] = useState({});
  const [isImageCompressing, setIsImageCompressing] = useState(false);
  const [compressionPromise, setCompressionPromise] = useState(null);
  const [progressionProgress, setProgressionProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const avatarUploadElement = useRef(null);
  const textAreaElement = useRef(null);

  const openToast = useToast();
  const dialog = useDialog();

  const [{ progressBackground }, progressSpring] = useSpring(() => ({
    config: config.molasses,
    progressBackground: getGradient(0)
  }));

  const updateBio = async ({ target }) => {
    textAreaElement.current.style.height = '';
    textAreaElement.current.style.height = `${textAreaElement?.current?.scrollHeight + 6}px`;
    setProfileData({
      ...profileData,
      [target.name]: target.value
    });
  };

  const updateAvatar = async ({ target }) => {
    if (target.files.length === 0) {
      return;
    }
    resetProgressGradient();
    toBase64(target.files[0])
      .then((avatar) => setProfileData({ ...profileData, avatar }));

    setCompressionPromise(compressAvatar(target.files[0])
      .then(toBase64)
      .then(value => {
        setProfileData({
          ...profileData,
          [target.name]: value
        });
        return value;
      }));
  };

  const compressAvatar = async (imageFile) => {
    console.debug(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    try {
      setIsImageCompressing(true);
      const imageCompression = await import('browser-image-compression');
      const compressedFile = await imageCompression.default(imageFile, {
        maxSizeMB: 0.1,
        useWebWorker: true,
        onProgress: setProgressionProgress
      });
      console.debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      setIsImageCompressing(false);
      return compressedFile;
    } catch (error) {
      console.error(error);
      openToast({
        type: ToastTypes.ERROR,
        title: 'Error',
        message: 'Error compressing image'
      });
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const dispatchData = {
      ...profileData,
      bio: textAreaElement.current.value
    };

    if (isImageCompressing) {
      dispatchData.avatar = await Promise.resolve(compressionPromise);
    }

    dispatch(updateProfile(dispatchData))
      .then(r => {
        setIsSubmitting(false);
        let toast = {};
        let success = false;
        if (!(/fulfilled/gi).test(r.type)) {
          toast = {
            title: 'Error',
            message: 'Image is too large or wrong file format. Please try again.',
            type: ToastTypes.ERROR
          };
        } else {
          success = true;
          toast = {
            title: 'Success',
            message: 'Profile updated successfully.',
            type: ToastTypes.SUCCESS
          };
        }
        finishProgressGradient(success);
        openToast(toast);
      });
  };

  const openAvatarInput = (e) => {
    avatarUploadElement.current.click();
  };

  const deleteAvatar = (e) => {
    e.stopPropagation();
    console.debug(avatarUploadElement.current);
    avatarUploadElement.current.value = null;
    setProfileData({
      ...profileData,
      avatar: null
    });
  };

  const resetProgressGradient = () => progressSpring.start({
    config: { duration: 1 },
    progressBackground: getGradient(0)
  });

  const finishProgressGradient = (success) => progressSpring.start({
    config: { duration: 1 },
    progressBackground: success ? getGradient(100) : getErrorGradient(100)
  });

  useEffect(() => {
    setProfileData({
      ...profileData,
      avatar: currentUser?.avatar
    });
    textAreaElement.current.style.height = `${textAreaElement?.current?.scrollHeight + 6}px`;
  }, [currentUser]);

  useEffect(() => {
    isSubmitting && progressSpring.start({
      config: progressionProgress < 90 ? config.molasses : config.stiff,
      progressBackground: getGradient(progressionProgress)
    });
  }, [progressionProgress]);

  useEffect(() => {
    document.title = 'Edit your Profile';
  }, []);

  return (
    <div className={ `${styles.EditProfileForm} infoPage` }>
      <div className='actionBar'>
        <BackIcon className="back action" onClick={ () => navigate(-1) } />
      </div>
      <div className='pageTitleText'>
        EDIT YOUR
      </div>
      <div className='pageTitleWrapper'>
        <AccountTitle className='pageTitle withText' />
      </div>
      <form className="form" onSubmit={ submit }>
        <animated.div
          className={ [styles.EditAvatarWrapper, styles.AvatarWrapper, 'action'].join(' ') }
          onClick={ openAvatarInput }
          style={ {
            background: progressBackground
          } }>
          { profileData &&
            profileData.avatar
            ? <img alt="avatar"
              className={ [styles.EditAvatar, styles.Avatar].join(' ') }
              src={ profileData?.avatar || currentUser?.avatar }
            />
            : <Avatar
              variant='ring'
              colors={ appConfig.initialAvatar.colors }
              name={ currentUser?.uuid }
            />
          }
          <div id="deleteAvatar" className={ styles.Delete } onClick={ (e) => {
            e.stopPropagation();
            dialog({
              title: `Delete Profile Picture?`,
              action: {
                confirmText: 'Delete',
                callback: deleteAvatar
              },
              content: `Are you sure you want to delete your profile picture?`
            });
          } }>
            <DeleteIcon />
          </div>
        </animated.div>
        <div className={ [styles.CenterText, 'action'].join(' ') } onClick={ openAvatarInput }>
          <UploadIcon />
          <div>
            Upload a profile picture
          </div>
        </div>
        <input hidden ref={ avatarUploadElement } type="file" name="avatar" onChange={ updateAvatar } />
        <label htmlFor='bio'>bio</label>
        <textarea ref={ textAreaElement } className={ styles.BioTextarea }
          type="text" name="bio"
          placeholder="Write something about yourself..."
          onChange={ updateBio } defaultValue={ currentUser?.bio } />
        <div className={ styles.CharacterCount }>{ profileData?.bio?.length || 0 }/300</div>
        <input type="submit" value="Save Changes" disabled={ isSubmitting } />
      </form>
    </div>
  );
};

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const getGradient = (progress) => {
  return `conic-gradient(#FFDC14 ${progress}%, #29282e ${progress}%)`;
};

const getErrorGradient = (progress) => {
  return `conic-gradient(#FF5747 ${progress}%, #29282e ${progress}%)`;
};

export default EditProfileForm;
