
const RekordboxHowTo = () => {
  return (
    <div>
      <h1>How to broadcast with rekordbox for free</h1>
    </div>
  );
};

export default RekordboxHowTo;
