import { useSelector } from 'react-redux';
import womanLeft from './images/wavyWomanLeft.webp';
import womanRight from './images/wavyWomanRight.webp';
import styles from './home.module.css';
import { useEffect } from 'react';
import { useInView, animated } from '@react-spring/web';

const InfoWithImageScreen = () => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);

  useEffect(() => {
  }, []);

  const [gradient, gradientLeftSpring] = useInView(() => ({
    from: { left: '0%' },
    to: { left: '-100%' },
    config: { mass: 50, tension: 200, friction: 50, bounce: 0 },
  }), { rootMargin: '-150px 0px', once: true });


  const [textContent, textContentSpring] = useInView(() => ({
    from: { y: 50, opacity: 0 },
    to: { y: -50, opacity: 1 },
  }), { rootMargin: '-175px 0px', once: true });

  const [rightImage, rightImageSpring] = useInView(() => ({
    from: { y: 100, opacity: 0 },
    to: { y: 0, opacity: 1 },
    config: { mass: 10, tension: 200, friction: 50, bounce: 0 },

  }), { rootMargin: '-10px 0px', once: true });

  const [leftImage, leftImageSpring] = useInView(() => ({
    from: { y: 100, opacity: 0 },
    to: { y: 0, opacity: 1 },
    config: { mass: 20, tension: 170, friction: 50, bounce: 0 },

  }), { rootMargin: '-10px 0px', once: true });


  return (
    <div className={[styles.InfoWithImageScreen].join(' ')}>
      <animated.div ref={gradient} style={{ right: gradientLeftSpring.left }} className={styles.GradientBlockLeft} />
      <animated.div style={gradientLeftSpring} className={styles.GradientBlockRight} />
      <animated.div ref={textContent} style={textContentSpring} className={styles.Content}>
        <h1>Livesets with no Compromises</h1>
        <p>
          Onda enables everyone to broadcast DJ sets directly from their DJ platform (like Traktor,
          VirtualDJ, Rekordbox or Serato) <span className={styles.Underline}>for free. </span>
        </p>
        {!isAuthenticated &&
          <button className={styles.JoinNowButton}
            onClick={() => {
              document.querySelector('.broadcast-list')
                .scrollIntoView({ behavior: 'smooth' });
            }}>
            Go to Livestreams
          </button>}
      </animated.div>
      <div className={styles.ImagesWrapper}>
        <animated.img ref={leftImage} style={leftImageSpring} src={womanLeft} alt="womanLeft" />
        <animated.img ref={rightImage} style={rightImageSpring} src={womanRight} alt="womanRight" />
        <div className={styles.ImagesBackground} />
      </div>
    </div>
  );
};

export default InfoWithImageScreen;
