import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Avatar from 'boring-avatars';
import SubscribeButton from './SubscribeButton';
import { getSubscribedUsers, unsubscribeFromUser } from './pushNotificationSlice';
import config from '../config';
import styles from './subscriptionManager.module.css';

import { ReactComponent as BackIcon } from '../icons/Back-Icon-Left.svg';
import { ReactComponent as DeleteIcon } from '../icons/Delete-Icon.svg';
import TopUsersBySubscriptions, { UiModes } from '../user/TopUsersBySubscriptions';
import { useDialog } from '../utils/DialogContext';
import { useMediaQuery } from 'react-responsive';

const SubscriptionManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isWideScreen = useMediaQuery({ query: '(min-width: 1000px)' });

  const subscribedUUIDs = useSelector(state => state.pushNotification.subscribedUsers);
  const users = useSelector(state => state.pushNotification.subscribedUserObjects);
  const dialog = useDialog();

  const unsubscribeAll = () => {
    subscribedUUIDs.forEach(uuid => dispatch(unsubscribeFromUser(uuid)));
  };

  useEffect(() => {
    if (subscribedUUIDs.length === 0) {
      return;
    }
    dispatch(getSubscribedUsers(subscribedUUIDs));
  }, [subscribedUUIDs]);

  useEffect(() => {
    document.title = 'Your Subscriptions on Onda the free live streaming platform for DJs';
  });

  // TODO remove duplication
  const renderAvatar = (user) => {
    if (user?.username?.toLowerCase() === user.username?.toLowerCase()) {
      if (user.avatar) {
        return (
          <img className={styles.Avatar}
            src={user.avatar}
            alt="avatar" />);
      } else {
        return (
          <Avatar
            variant="ring"
            name={user.uuid}
            colors={config.initialAvatar.colors}
          />);
      }
    } else {
      return (<></>);
    }
  };

  return (
    <div className="infoPage">
      <div className='actionBar'>
        <BackIcon className="back action" onClick={() => navigate(-1)} />
        {users.length > 0 &&
          <div className={`actionWithIconAndText action ${styles.UnsubscribeAll}`} onClick={() => dialog({
            title: `Unsubscribe from all users?`,
            action: {
              confirmText: 'Unsubscribe',
              callback: unsubscribeAll
            },
            content: `Are you sure you want to stop getting any notifications?`
          })} >
            <DeleteIcon />
            <div>Unsubscribe All</div>
          </div>
        }
      </div>
      <h1>My Subscriptions</h1>
      <div className={styles.ContentWrapper}>
        <div className={styles.SubscriptionWrapper}>
          {users.map((user) => (
            <div key={user.uuid} className={styles.Subscription}>
              <div className={[styles.SubscriptionLeft, 'action'].join(' ')}
                onClick={() => navigate(`/user/${user.username}`)}>
                <div className={styles.AvatarWrapper}>
                  {renderAvatar(user)}
                </div>
                <div className='fontBold'>{user.username}</div>
              </div>
              {isWideScreen &&
                <div className={styles.SubscriberCount}>
                  {user.subscriberCount} Subscribers
                </div>
              }
              <SubscribeButton user={user} color="grey" />
            </div>
          ))}
                  {users.length === 0 &&
          <div className={styles.NoSubscriptions}>
            <p>
              It looks like you haven't subscribed to anyone yet.
              You can subscribe to users by clicking the subscribe button on their profile.
              Then you will get a notification when they start a new dj live stream.
            </p>
          </div>
        }
        </div>
        <div>
          <TopUsersBySubscriptions uiMode={isWideScreen ? UiModes.CIRCLE : UiModes.SQUARE} />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionManager;
