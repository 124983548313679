import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginWithGoogle, signup } from './authenticationSlice';
import userPropertyValidators from './userPropertyValidators';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import InputErrorMessage from '../utils/InputErrorMessage';

import { ReactComponent as SignupTitle } from '../titles/Signup.svg';

import styles from './authentication.module.css';
import { checkIfGoogleUserExists } from '../user/userSlice';
import { ToastTypes, useToast } from '../toast/ToastContext';
import Checkbox from '../utils/Checkbox';
import { useMediaQuery } from 'react-responsive';
import SignupImage from './images/SignupImage.webp';


const SignupForm = () => {
  const properties = ['username', 'email', 'password', 'confirmPassword'];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openToast = useToast();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });

  const [signupData, setSignupData] = useState();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorElement, setErrorElement] = useState(null);
  const [allPropertiesFilled, setAllPropertiesFilled] = useState(false);


  const updateSignupData = ({ target }) => {
    setSignupData({
      ...signupData,
      [target.name]: target.value,
    });
  };

  useEffect(
    () => setAllPropertiesFilled(properties.every(property => signupData && signupData[property]) && acceptedTerms),
    [signupData]);

  useEffect(() => {
    document.title = 'Signup to onda to start streaming your dj sets for free';
  }, []);

  const sendSignup = (e) => {
    e.preventDefault();
    try {
      userPropertyValidators.forEach(validate => validate(signupData));
    } catch (e) {
      console.error(e.message);
      setErrorMessage(e.message);
      setErrorElement(e.propertyName);
      return;
    }
    console.debug('sendSignup', signupData);
    dispatch(signup({ ...signupData, subscribedToNewsletter }))
      .then(handleError);
  };

  const handleError = (errorWrapper) => {
    if (!errorWrapper.error) {
      setErrorMessage(null);
      setErrorElement(null);
      navigate('success', {
        replace: true
      });
      return;
    } else {
      const errorDTO = JSON.parse(errorWrapper.error.message);
      setErrorMessage(errorDTO.message);
      setErrorElement(errorDTO.propertyName);
    }
  };

  const handleGoogleLoginSuccess = async credentialResponse => {
    const isUserAlreadyRegistered = await checkIfGoogleUserExists(credentialResponse.credential);
    if (isUserAlreadyRegistered) {
      console.debug('User already registered');
      dispatch(loginWithGoogle(credentialResponse))
        .then(r => {
          if (r.error) {
            openToast({
              type: ToastTypes.ERROR,
              message: 'Login failed',
              title: 'Error'
            });
          } else {
            openToast({
              type: ToastTypes.SUCCESS,
              message: 'Welcome back!',
              title: 'Login successful'
            });
            navigate('/', {
              replace: true
            });
          }
        });
    } else {
      navigate('google', {
        replace: true,
        state: {
          credential: credentialResponse.credential
        }
      });
    }
  };

  useEffect(() => {
    if (!acceptedTerms) {
      setAllPropertiesFilled(false);
    } else if (properties.every(property => signupData && signupData[property])) {
      setAllPropertiesFilled(true);
    }
  }, [acceptedTerms]);

  return (
    <div className={`${styles.SignupForm}`}>
      {isLargeScreen && (
        <div className={styles.SignupScreenImage}>
          <img src={SignupImage} alt="Signup image screen" />
          <div className={styles.CatchLine} >
            Join the Party!
          </div>
        </div>
      )}
      <div className={styles.SignupFormElementWrapper}>
        <div className={styles.SignupFormElement}>
          <SignupTitle className={styles.TitleSvg} />
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={() => openToast({
              type: ToastTypes.ERROR,
              message: 'Login failed',
              title: 'Error'
            })}
            text='signup_with'
            shape='rect'
            logo_alignment='left'
            theme='filled_black'
            size='large'
            context='signup'
          />
          <div className={styles.FormDivider}>
            <div>Or</div>
          </div>
          <form className={styles.form} onSubmit={sendSignup}>
            <label htmlFor="username">Username</label>
            <input name="username" placeholder="Username" type="text" autoComplete="on" onChange={updateSignupData} />
            <InputErrorMessage shouldRender={errorElement === 'username'} message={errorMessage} />
            <label htmlFor="email">email</label>
            <input name="email" placeholder="Email" type="email" autoComplete="on" onChange={updateSignupData} />
            <InputErrorMessage shouldRender={errorElement === 'email'} message={errorMessage} />
            <label htmlFor="password">password</label>
            <input name="password" placeholder="Password" type="password" autoComplete="on" onChange={updateSignupData} />
            <InputErrorMessage shouldRender={errorElement === 'password'} message={errorMessage} />
            <label htmlFor="confirmPassword">confirm Password</label>
            <input name="confirmPassword" placeholder="Confirm Password" autoComplete="on" type="password" onChange={updateSignupData} />
            <InputErrorMessage shouldRender={errorElement === 'confirmPassword'} message={errorMessage} />
            <Checkbox checked={acceptedTerms} required handleChange={() => setAcceptedTerms(!acceptedTerms)} label='I accept the Terms of Service and Privacy Policy' />
            <Checkbox checked={subscribedToNewsletter} handleChange={() => setSubscribedToNewsletter(!subscribedToNewsletter)} label='I want to receive the Onda Newsletter' />
            <input type="submit" value="Sign Up" disabled={!allPropertiesFilled} />
          </form>
          <div className={styles.PageInfo}>
            You have an account? <Link className='textLink' to="/login">Login here</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
