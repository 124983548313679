import { ReactComponent as Dance1 } from './images/dance_animation/dance1.svg';
import { ReactComponent as Dance2 } from './images/dance_animation/dance2.svg';
import { ReactComponent as Dance3 } from './images/dance_animation/dance3.svg';
import { ReactComponent as Dance4 } from './images/dance_animation/dance4.svg';
import { ReactComponent as Dance5 } from './images/dance_animation/dance5.svg';
import { ReactComponent as Dance6 } from './images/dance_animation/dance6.svg';
import { ReactComponent as Dance7 } from './images/dance_animation/dance7.svg';
import { ReactComponent as Dance8 } from './images/dance_animation/dance8.svg';
import { ReactComponent as Dance9 } from './images/dance_animation/dance9.svg';
import { ReactComponent as Dance10 } from './images/dance_animation/dance10.svg';


import styles from './YellowBackgroundSection.module.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSprings, animated, config as springConfig } from '@react-spring/web';

const YellowBackgroundSection = () => {
    const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
    const [danceSprings] = useSprings(10, i => ({
        config: {
            ...springConfig.wobbly,

        },
        from: { transform: 'translateY(0px)' },
        to: async (next, cancel) => {
            await next({ transform: 'translateY(-10px)' });
            await next({ transform: 'translateY(0px)' });
        },
        delay: i * 200,
        loop: true,
        reset: true,
    }));

    const letters = [Dance1, Dance2, Dance3, Dance4, Dance5, Dance6, Dance7, Dance8, Dance9, Dance10];

    return (
        <div className={styles.YellowBackgroundSection}>
            <div className={styles.YellowBackground}>
                <h1>made <br></br> by DJs for DJs</h1>
                <p>
                    Sign up now and start your broadcast for free!
                    All you need is your DJ software like Traktor,
                    Virtual DJ, etc.
                </p>
                <button>
                    {!isAuthenticated
                        ? (<Link to="/signup">
                            Sign up now
                        </Link>)
                        : (<Link to="/account/streamdata">
                            Start Livestream
                        </Link>)}
                </button>
                <div className={styles.DanceWrapper}>
                    {danceSprings.map((props, i) => {
                        const Letter = letters[i];
                        return (
                            <animated.div key={i} style={props}>
                                <Letter />
                            </animated.div>
                        );
                    })}
                </div>
                {!isAuthenticated &&
                    <div className={styles.LoginLinkParagraph}>
                        Already a member?
                        <Link to="/login">
                            &nbsp;Login!
                        </Link>
                    </div>}
            </div>
        </div>
    );
};

export default YellowBackgroundSection;
