import { useDispatch, useSelector } from 'react-redux';
import SubscribeButton from '../pushnotification/SubscribeButton';
import { setPlaying } from '../audio/audioSlice';

import styles from './liveSet.module.css';

import { ReactComponent as BackIcon } from '../icons/Back-Icon-Left.svg';
import { ReactComponent as ShareIcon } from '../icons/Share-Icon.svg';
import { ReactComponent as PlayIcon } from '../icons/Play-Icon.svg';
import { ReactComponent as PauseIcon } from '../icons/Pause-Icon.svg';

import { useEffect, useState } from 'react';
import config from '../config';
import Avatar from 'boring-avatars';
import { useNavigate, useParams } from 'react-router-dom';
import { setCurrentUsername } from '../audio/audioSlice';
import { useSpring, animated } from '@react-spring/web';

const LiveSet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const audioState = useSelector(state => state.audio);
  const { username, streamId } = useParams();
  const [isLive, setIsLive] = useState(false);
  const sourcesInitialized = useSelector(state => state.icecast.sourcesInitialized);
  const sourceFromLiveSources = useSelector(state => state.icecast.sources.find(source => source.id === streamId));

  const [source, setSource] = useState(null);


  useEffect(() => {
    if (sourceFromLiveSources) {
      setIsLive(true);
      setSource(sourceFromLiveSources);
    } else {
      fetch(`${config.serverUrl}/livestream/id/${streamId}`)
        .then(response => response.json())
        .then(data => {
          setIsLive(false);
          setSource(data);
        });
    }
  }, [sourceFromLiveSources]);

  useEffect(() => {
    if (!source) {
      return;
    }
    if (source.user.username !== username) {
      navigate(`/user/${source.user.username}/liveset/${streamId}`);
    }
  }, [source]);


  const [{ rotate }, spring] = useSpring(() => ({
    loop: true,
    rotate: 0,
  }));

  useEffect(() => {
    if (audioState.playing) {
      spring.start({
        loop: true,
        rotate: 360 + rotate.get(),
        config: { duration: 5000 }
      });
    } else {
      spring.stop();
      const r = rotate.get();
      spring.start({ rotate: r + 360 - (r % 360) - 1, config: { duration: 160 } },);
      console.log(r);
    }
  }, [audioState]);


  const share = async () => {
    try {
      await navigator.share({
        title: `${audioState.currentUsername}'s Liveset`,
        text: `Listen to ${audioState.currentUsername}'s liveset on ondaradio.live`,
        url: `${config.baseUrl}/user/${audioState.currentUsername}/liveset/${streamId}`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // TODO move to helper class
  const renderDuration = () => {
    const seonds = ((new Date()).getTime() - Date.parse(source?.startTime)) / 1000;
    const secNum = parseInt(seonds, 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;

    return [hours, minutes]
      .filter((v) => v !== 0)
      .join('h ') + 'm ago';
  };


  return (
    <div className={[styles.ExpandedPlayer].join(' ')}>
      <div className={styles.ExpandedPlayerShim}></div>
      {!sourcesInitialized
        ? <div className={styles.ExpandedPlayerLoading}>Loading...</div>
        : <>
          <div className={styles.ExpandedPlayerContent}>
            <div className={styles.ExpandedPlayerHeader}>
              <div onClick={() => navigate(-1)} className='action'>
                <BackIcon className={[styles.Back, styles.Icon].join(' ')} />
              </div>
              <div className={styles.ExpandedPlayerHeaderInfo}>
                {isLive && <div>{source?.listenerCount} Listeners</div>}
                <div className={styles.DurationAgo}> {renderDuration()}</div>
              </div>
              <div onClick={share} className='action'>
                <ShareIcon className={styles.Icon} />
              </div>
            </div>
            <div className={styles.ExpandedPlayerAvatarBig}>
              {isLive &&
                <div className={styles.ExpandedPlayerPlayIconWrapper}
                  onClick={() => {
                    dispatch(setCurrentUsername(source.user.username));
                    dispatch(setPlaying(!audioState.playing));
                  }} >
                  {audioState.playing
                    ? <PauseIcon className={[styles.ExpandedPlayerPlay, styles.Icon].join(' ')} />
                    : <PlayIcon className={[styles.ExpandedPlayerPlay, styles.Icon].join(' ')} />
                  }
                </div>
              }
              {
                source &&
                  source.user?.avatar
                  ? <img src={source?.user?.avatar}
                    alt="avatar" />
                  : <Avatar
                    variant="ring"
                    name={source?.user?.uuid}
                    colors={config.initialAvatar.colors}
                  />
              }
              <animated.svg style={{ rotate }} className={styles.Circle} xmlns="http://www.w3.org/2000/svg" width="194" height="193" viewBox="0 0 194 193" fill="none">
                <path d="M193 96.5C193 149.238 150.024 192 97 192C43.9758 192 1 149.238 1 96.5C1 43.7617 43.9758 1 97 1C150.024 1 193 43.7617 193 96.5Z" stroke="url(#paint0_linear_873_6989)" strokeWidth="2" />
                <defs>
                  <linearGradient id="paint0_linear_873_6989" x1="97" y1="-21" x2="97" y2="193" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#552AFF" />
                    <stop offset="1" stopColor="#552AFF" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </animated.svg>
            </div>
            <div className={styles.ExpandedPlayerGenre}>#{source?.genre}</div>
            <h1 className={styles.ExpandedPlayerStreamName}>{source?.serverName}</h1>
            {/*             {isLive && (
              <div className={styles.ExpandedPlayerTrack}>
                <label>Current song</label>
                <div className={styles.Title}>{source?.title}</div>
                <div className={styles.Artist}>{source?.artist}</div>
              </div>
            )} */}
            <label className={styles.ArtistLabel}>Artist</label>
            <div className={styles.UserDetails}>
              <div className={styles.Left}>
                {/*                 <div className={styles.AvatarWrapper}>
                  {source &&
                    source.user?.avatar
                    ? <img src={source?.user?.avatar}
                      alt="avatar" />
                    : <Avatar
                      variant="ring"
                      name={source?.user?.uuid}
                      colors={config.initialAvatar.colors}
                    />}
                </div> */}
                <div>
                  <div className={styles.UserName}>{source?.user?.username}</div>
                  <div className={styles.SubscriberCount} >{source?.user?.subscriberCount} Subscribers</div>
                </div>
              </div>
              <SubscribeButton className={styles.SubscribeButton}
                color="grey"
                user={source?.user} />
            </div>
            <div className={styles.Description} >
              <label>Description</label>
              <div>
                {source?.serverDescription}
              </div>
            </div>
            <div className={styles.TrackHistory}>
              <h3>Played Tracks</h3>
              <div className={styles.TrackList}>
                {[...(source?.tracks || [])].reverse().map((trackInfo, index, { length }) => (
                  <div className={[styles.TrackEntry, isLive ? styles.Live : ''].join(' ')} key={trackInfo + index}>
                    <div className={styles.TrackIndex}>{length - index}</div>
                    <div className={styles.TrackInfo}>
                      <div>{trackInfo.title}</div>
                      <div>{trackInfo.artist}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>}
    </div>
  );
};

export default LiveSet;
