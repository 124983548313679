import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from '@react-spring/web';

import styles from './askForCookies.module.css';


const AskForCookies = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [{ y, opacity, shimOpacity }, spring] = useSpring(() => ({
    config: config.gentle,
    y: '100%',
    opacity: 0,
    shimOpacity: 0,
  }));

  useEffect(() => {
    const acceptedFromLocalStorage = localStorage.getItem('acceptedCookies');
    if (acceptedFromLocalStorage && acceptedFromLocalStorage === 'true') {
      setAccepted(true);
    } else {
      setTimeout(() => {
        setIsOpen(true);
      }, 3500);
    }
  }, []);

  const accept = () => {
    setAccepted(true);
    setIsOpen(false);
    localStorage.setItem('acceptedCookies', true);
  };

  const decline = () => {
    setAccepted(false);
    setIsOpen(false);
    localStorage.setItem('acceptedCookies', false);
  };

  useEffect(() => {
    spring.start({
      y: isOpen ? '0' : '100%',
      opacity: isOpen ? 1 : 0,
      shimOpacity: isOpen ? 0.8 : 0
    });
  }, [isOpen]);

  return (
    <div>
      <animated.div className={styles.Shim} style={{
        opacity: shimOpacity,
        pointerEvents: isOpen ? 'all' : 'none'
        }}/>
      <animated.div className={styles.AcceptCookies} style={{ y, opacity }}>
        <div className={styles.Text}>
          <h3>Cookie Time 🍪☕</h3>
          <p>
            This website uses cookies to ensure you get the best experience ondaradio.live.
            <br></br>
            <br></br>
            You need them e.g. to sign in with your Google account, remember who you are following, etc..
          </p>
        </div>
        <div className={styles.Buttons}>
          <button className={[styles.Button, 'grey'].join(' ')} onClick={decline}>
            Decline
          </button>
          <button className={[styles.Button].join(' ')} onClick={accept}>
            Accept
          </button>
        </div>
      </animated.div>
    </div>
  );
};


export default AskForCookies;
