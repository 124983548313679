import {ReactComponent as Logo} from './images/ondaFontLogo.svg';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={styles.Footer}>
            <div className={styles.Left}>
                <div><Link to="/imprint">Legal</Link></div>
                <div>Contact</div>
                <div>Instagram</div>
            </div>
            <div className={styles.Right}>
                <div>© 2023 Onda Radio</div>
                <Logo />
            </div>
        </div>
    );
};

export default Footer;
