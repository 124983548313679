import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  currentUsername: null,
  error: null,
  volume: 1,
  playing: false
};

export const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
    setCurrentUsername: (state, action) => {
      state.currentUsername = action.payload;
      state.playing = true;
    },
    setPlaying: (state, action) => {
      state.playing = action.payload;
    },
  }
});

export const { setVolume, setCurrentUsername, setPlaying } = audioSlice.actions;

export default audioSlice.reducer;
