import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../config';
import CONFIG from '../config';
import { handleHttpError } from '../utils/fetchUtils';

const initialState = {
  sources: [],
  sourcesInitialized: false,
  playing: {},
  currentSort: 'mostRecent',
  lastStreams: [],
  streamData: {}
};

export const updateSources = createAsyncThunk(
  'icecast/update',
  async () => {
    return await fetch(`${CONFIG.serverUrl}/livestream/active`)
      .then(handleHttpError);
    // TODO sort here...
  }
);


const _sortSources = (state, action) => {
  switch (action.payload) {
    case 'mostRecent':
      state.sources = state.sources
        .sort(_sortByMostRecent);
      break;
    case 'mostListeners':
      state.sources = state.sources
        .sort(_sortByListeners);
      break;
    default: break;
  }
  state.currentSort = action.payload;
};

export const getLastStreams = createAsyncThunk(
  'icecast/streams',
  async (username) =>
    fetch(`${CONFIG.serverUrl}/livestream/user/${username}`)
      .then(handleHttpError)
);

export const getStreamData = createAsyncThunk(
  'icecast/streams/data',
  async () => {
    return fetch(`${CONFIG.serverUrl}/livestream/config/user/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: config.auth.credentialsMode
    })
      .then(handleHttpError);
  }
);

export const deleteLiveStream = createAsyncThunk(
  'icecast/streams/delete',
  async (endTime) => {
    return fetch(`${CONFIG.serverUrl}/livestream/delete/me?endTime=${endTime}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: config.auth.credentialsMode
    })
      .then(handleHttpError);
  }
);

export const icecastSlice = createSlice({
  name: 'icecast',
  initialState,
  reducers: {
    sortSources: _sortSources
  },
  extraReducers: builder => {
    builder
      .addCase(updateSources.fulfilled, (state, action) => {
        console.debug(action.payload);
        state.sources = action.payload;
        state.sources = sortSourceList(action.payload, state.currentSort);
        if (state.sourcesInitialized === false) {
          state.sourcesInitialized = true;
        }
      })
      .addCase(getLastStreams.fulfilled, (state, action) => {
        console.debug('Last Streams', action.payload);
        state.lastStreams = action.payload.sort((a, b) => a.endTime > b.endTime ? 1 : -1);
      })
      .addCase(getStreamData.fulfilled, (state, action) => {
        console.debug('Stream config', action.payload);
        state.streamData = action.payload;
      })
      .addCase(deleteLiveStream.fulfilled, (state, action) => {
        console.debug('Stream deleted', action.payload);
        state.lastStreams = state.lastStreams.filter(s => s.endTime !== action.payload.endTime);
      });
  }
});

const sortSourceList = (list, sort) => {
  switch (sort) {
    case 'mostRecent':
      return list.sort(_sortByMostRecent);
    case 'mostListeners':
      return list.sort(_sortByListeners);
    default: break;
  }
};

const _sortByListeners = (p, c) => {
  if (p.listenerCount === c.listenerCount) {
    return 0;
  }
  return p.listenerCount < c.listenerCount ? 1 : -1;
};

const _sortByMostRecent = (p, c) => {
  const startTimeP = Date.parse(p.startTime);
  const startTimeC = Date.parse(c.startTime);
  if (startTimeP === startTimeC) {
    return 0;
  }
  return startTimeP < startTimeC ? 1 : -1;
};


export const { sortSources } = icecastSlice.actions;

export const selectSources = (state) => state.icecast.sources;

export default icecastSlice.reducer;
