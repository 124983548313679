import { lazy, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSpring, animated, config } from '@react-spring/web';
import ForgotPasswordForm from '../authentication/ForgotPasswordForm';
import GoogleSignupForm from '../authentication/GoogleSignupForm';
import LoginForm from '../authentication/LoginForm';
import ResetPasswordForm from '../authentication/ResetPasswordForm';
import SignupForm from '../authentication/SignupForm';
import SignupSuccessPage from '../authentication/SignupSuccessPage';
import VerifyAccountView from '../authentication/VerifyAccountView';
import Howto from '../howto/Howto';
import RekordboxHowTo from '../howto/Rekordbox';
import SeratoHowTo from '../howto/Serato';
import TraktorHowTo from '../howto/Traktor';
import VirtualDJHowTo from '../howto/VirtualDJ';
import BroadcastList from '../icecast/BroadcastList';
import StreamData from '../icecast/StreamData';
import ImprintPage from '../legal/ImprintPage';
import SubscriptionManager from '../pushnotification/SubscriptionManager';
import Account from '../user/Account';
import UserProfile from '../user/UserProfile';
import NotFound from '../utils/NotFound';
import EditProfileForm from '../user/EditProfileForm';
import LiveSet from '../icecast/LiveSet';
import Home from '../home/Home';

const AnimatedRoutes = () => {
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);

  const previousLocation = useRef(location);

  const [{ opacity, x }, spring] = useSpring(() => ({
    opacity: 1,
    x: '0%',
    config: {
      ...config.wobbly,
    }
  }));

  useEffect(() => {
    hideHeaderIfLoginOrSignup(location);
    if (location.pathname === previousLocation.current.pathname) {
      return;
    }

    window.scrollTo(0, 0);
    previousLocation.current = location;
    spring.start({ to: { opacity: 1 }, from: { opacity: 0 } });
  }, [location]);

  const hideHeaderIfLoginOrSignup = () => {
    if (location.pathname === '/login' || location.pathname === '/signup') {
      document.querySelector('header').style.display = 'none';
    } else {
      document.querySelector('header').style.display = 'flex';
    }
  };

  return (
    <animated.div style={ { opacity, x, width: '-webkit-fill-available' } }>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="account" element={ isAuthenticated ? <Account /> : <Navigate replace to="/" /> } />
        <Route path="account/edit" element={ isAuthenticated ? <EditProfileForm /> : <Navigate replace to="/" /> } />
        <Route path="account/streamdata" element={ isAuthenticated ? <StreamData /> : <Navigate replace to="/" /> } />
        <Route path="account/subscriptions" element={ <SubscriptionManager /> } />
        <Route path="account/verify/:token" element={ <VerifyAccountView /> } />
        <Route path="account/password/reset" element={ <ForgotPasswordForm /> } />
        <Route path="account/password/reset/token/:token" element={ <ResetPasswordForm /> } />
        <Route path="login" element={ !isAuthenticated ? <LoginForm /> : <Navigate replace to="/" /> } />
        <Route path="signup" element={ !isAuthenticated ? <SignupForm /> : <Navigate replace to="/" /> } />
        <Route path="signup/google" element={ !isAuthenticated ? <GoogleSignupForm /> : <Navigate replace to="/" /> } />
        <Route path="signup/success" element={ !isAuthenticated ? <SignupSuccessPage /> : <Navigate replace to="/" /> } />
        <Route path="howto" element={ <Howto /> } />
        <Route path="howto/traktor" element={ <TraktorHowTo /> } />
        <Route path="howto/virtualDJ" element={ <VirtualDJHowTo /> } />
        <Route path="howto/rekordbox" element={ <RekordboxHowTo /> } />
        <Route path="howto/serato" element={ <SeratoHowTo /> } />
        <Route path="user/:username" element={ <UserProfile /> } />
        <Route path="user/:username/liveset/:streamId" element={ <LiveSet /> } />
        <Route path="imprint" element={ <ImprintPage /> } />
        <Route path="*" element={ <NotFound /> } />
      </Routes>
    </animated.div>
  );
};

export default AnimatedRoutes;
