import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleHttpError } from '../utils/fetchUtils';
import { signupWithGoogle } from './authenticationSlice';
import InputErrorMessage from '../utils/InputErrorMessage';

import config from '../config';
import styles from './authentication.module.css';
import { ToastTypes, useToast } from '../toast/ToastContext';
import Checkbox from '../utils/Checkbox';

const GoogleSignupForm = () => {
  // TODO if no id_token, redirect to signup page

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const openToast = useToast();
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(false);
  const [isReadyToSignup, setIsReadyToSignup] = useState(false);

  const validateUsername = async ({ target: { value } }) => {
    setUsername(value);
    if (value.length < 3) {
      setError('Username must be at least 3 characters long');
      return;
    } else if (value.length > 20) {
      setError('Username must be at most 20 characters long');
      return;
    } else if (!(/^[a-zA-Z0-9_]*$/).test(value)) {
      setError('Username must contain only letters, numbers and underscores');
      return;
    }
    const doesUsernameExist = await fetch(`${config.serverUrl}/user/exists/username/${value}`)
      .then(handleHttpError)
      .then(json => json.exists);
    if (doesUsernameExist) {
      setError('Username is already taken');
      return;
    }
    setError(null);
  };

  const sendSignup = (e) => {
    e.preventDefault();
    console.debug('sendSignup');
    const idToken = location.state.credential;
    dispatch(signupWithGoogle({ idToken, username, subscribedToNewsletter }))
      .then(handleResponse);
  };

  const handleResponse = (errorWrapper) => {
    if (errorWrapper.error) {
      openToast({
        message: `Something went wrong: ${errorWrapper.error.message}`,
        type: ToastTypes.ERROR,
        title: 'Login failed'
      });
    } else {
      openToast({
        message: `Welcome back, ${username}!`,
        type: ToastTypes.SUCCESS,
        title: 'Login successful'
      });
      navigate('/', {
        replace: true
      });
    }
  };

  useEffect(() => {
    document.title = 'Signup with Google - Onda the free live streaming platform for DJs';
  }, []);

  useEffect(() => {
    if (acceptedTerms && error === null) {
      setIsReadyToSignup(true);
    } else {
      setIsReadyToSignup(false);
    }
  }, [username, acceptedTerms, error]);

  return (
    <div className={[styles.GoogleSignup, 'infoPage'].join(' ')}>
      <h1>Finalize your Signup</h1>
      <div className={styles.GoogleSignupDescription}>
        You have successfully logged in with Google. To finalize your registration please choose your username.
      </div>
      <form className={styles.form} onSubmit={sendSignup}>
        <label htmlFor="username">Username</label>
        <input name="username" placeholder="Username" type="text" autoComplete="on" onChange={validateUsername} />
        <InputErrorMessage shouldRender={error !== null} message={error} />
        <Checkbox label="I accept the Terms of Service" isChecked={acceptedTerms} handleChange={() => setAcceptedTerms(!acceptedTerms)} required />
        <Checkbox label="I want to receive the Onda newsletter" isChecked={subscribedToNewsletter} handleChange={() => setSubscribedToNewsletter(!subscribedToNewsletter)} />
        <input type="submit" disabled={!isReadyToSignup} value="Sign Me Up" />
      </form>
    </div>
  );
};

export default GoogleSignupForm;
