import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Broadcast from './Broadcast';

import styles from './icecast.module.css';
// import Sort from './Sort';

const BroadcastList = () => {
  const sources = useSelector(state => state.icecast.sources);
  const isAtLeastTablet = useMediaQuery({ query: '(min-width: 750px)' });
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);


  return (
    <div class='broadcast-list'>
      <div className={styles.BroadcastList} style={{
        paddingTop: isAuthenticated ? '0' : '78px',
      }}>
        {isAtLeastTablet &&
          <div className={styles.BroadcastListHeader}>
            <h1>
              Listen to New <br />
              Livestreams Now!
            </h1>
            <p>
              Stream Livesets from your Favorite Artists for Free
              and See which Tracks they Play.
            </p>
          </div>}
        <h1 style={{
          marginBottom: '1em',
          alignSelf: 'flex-start',
          marginLeft: 'var(--content-margin)'
        }}>
          Livestreams
        </h1>
        {/* <Sort /> */}
        <div className={styles.Broadcasts}>
          {sources.map(source => (
            <Broadcast key={source.listenUrl} source={source} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BroadcastList;
