import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated, config } from '@react-spring/web';

import { ReactComponent as SuccessIcon } from '../icons/Check-Symbol.svg';
import { ReactComponent as CloseIcon } from '../icons/Exit-Icon.svg';
import { ReactComponent as ErrorIcon } from '../icons/Error-Icon.svg';

import styles from './toast.module.css';


const ToastContext = React.createContext();

const ToastProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const [options, setOptions] = useState({
    title: '',
    message: '',
    type: ToastTypes.INFO
  });

  const openToast = (toastOptions) => {
    setOptions(toastOptions);
    if (isOpen) {
      spring.start({
        from: {
          scale: 1.05
        },
        to: {
          scale: 1
        },
        config: config.stiff
      });
      timer && clearTimeout(timer);
      waitAndClose();
    } else {
      setIsOpen(true);
      waitAndClose();
    }
  };

  const waitAndClose = () => {
    setTimer(setTimeout(() => {
      setIsOpen(false);
      setTimer(null);
    }, 4000));
  };

  const closeToast = () => {
    setIsOpen(false);
    timer && clearTimeout(timer);
  };

  useEffect(() => {
    spring.start({
      y: isOpen ? '0' : '-60%',
      opacity: isOpen ? 1 : 0
    });
  }, [isOpen]);

  const [{ y, opacity, scale }, spring] = useSpring(() => ({
    config: config.stiff,
    y: '-60%',
    opacity: 0,
    scale: 1
  }));

  const renderTypeIcon = () => {
    switch (options.type) {
      case ToastTypes.SUCCESS:
      case ToastTypes.INFO:
        return <SuccessIcon />;
      case ToastTypes.ERROR:
        return <ErrorIcon />;
      default:
        return <SuccessIcon />;
    }
  };

  return (
    <ToastContext.Provider value={openToast}>
      {children}
      <animated.div className={styles.Toast}
        style={{
          opacity, y, scale,
          pointerEvents: isOpen ? 'all' : 'none'
        }}>
        <div className={styles.LeftAlign}>
          <div className={[styles.TypeIcon, styles[options.type]].join(' ')}>
            {renderTypeIcon()}
          </div>
          <div className={styles.Content}>
            <h2>{options.title}</h2>
            <div className={styles.Message}>
              {options.message}
            </div>
          </div>
        </div>
        <div className={styles.Close} onClick={closeToast}>
          <CloseIcon />
        </div>
      </animated.div>
    </ToastContext.Provider>
  );
};

export const ToastTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
};
export const useToast = () => useContext(ToastContext);
export default ToastProvider;
