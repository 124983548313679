import { Link } from 'react-router-dom';

import styles from './notFound.module.css';
import { ReactComponent as NotFoundIcon } from './notFound.svg';

const NotFound = () => {
  return (
    <div className={styles.NotFound}>
      <NotFoundIcon />
      <h1>Got lost in the club?</h1>
      <p>Sorry, we can't find the page you're looking for.</p>
      <h1>Get back onda dancefloor!</h1>
      <Link to="/">
        <button>
          start
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
