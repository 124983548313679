import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, loginWithGoogle } from './authenticationSlice';
import { Link, useNavigate } from 'react-router-dom';
import styles from './authentication.module.css';

import InputErrorMessage from '../utils/InputErrorMessage';

import { ReactComponent as LoginTitle } from '../titles/Login.svg';

import LoginImage from './images/LoginScreenImage.webp';
import { GoogleLogin } from '@react-oauth/google';
import { checkIfGoogleUserExists } from '../user/userSlice';
import { ToastTypes, useToast } from '../toast/ToastContext';
import { useMediaQuery } from 'react-responsive';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openToast = useToast();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorElement, setErrorElement] = useState(null);

  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  });

  const updateLoginData = ({ target: { name, value } }) =>
    setLoginData({
      ...loginData,
      [name]: value
    });

  const sendLogin = (e) => {
    console.debug('sendLogin');
    e.preventDefault();
    dispatch(login(loginData))
      .then(handleError);
  };

  const handleError = (errorWrapper) => {
    if (!errorWrapper.error) {
      setErrorMessage(null);
      setErrorElement(null);
      return;
    } else {
      const errorDTO = JSON.parse(errorWrapper.error.message);
      setErrorMessage(errorDTO.message);
      setErrorElement(errorDTO.propertyName);
    }
  };

  useEffect(() => {
    console.debug('useEffect isAuthenticated', isAuthenticated);
    if (isAuthenticated) {
      openToast({
        message: `Welcome back, ${loginData.username}!`,
        type: ToastTypes.SUCCESS,
        title: 'Login successful'
      });
      navigate('/', {
        replace: true
      });
    }
  }, [isAuthenticated]);

  const handleGoogleLoginSuccess = async credentialResponse => {
    console.debug('Google login success');
    const isUserAlreadyRegistered = await checkIfGoogleUserExists(credentialResponse.credential);
    if (!isUserAlreadyRegistered) {
      navigate('/signup/google', {
        state: {
          credential: credentialResponse.credential
        }
      });
    } else {
      dispatch(loginWithGoogle(credentialResponse))
        .then(r => {
          if (r.error) {
            openToast({
              message: 'There was an error logging in with Google. Please try again later.',
              type: ToastTypes.ERROR,
              title: 'Login failed'
            });
          } else {
            openToast({
              message: `Welcome back!`,
              type: ToastTypes.SUCCESS,
              title: 'Login successful'
            });
            navigate('/', {
              replace: true
            });
          }
        });
    }
  };

  useEffect(() => {
    document.title = 'Login to onda to start streaming your dj sets';
    console.debug('Login mounted');
  }, []);

  return (
    <div className={`${styles.LoginForm}`}>
      <div className={styles.LoginFormElementWrapper}>
        <div className={styles.LoginFormElement}>
          <LoginTitle className={styles.TitleSvg}/>
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={() => console.debug('Login Failed')}
            text='signin_with'
            shape='rect'
            logo_alignment='left'
            theme='filled_black'
            size='large'
            locale='en'
            context='signin'
          />
          <div className={styles.FormDivider}>
            <div>Or</div>
          </div>
          <form className={styles.form} onSubmit={sendLogin}>
            <label htmlFor="username">Username</label>
            <input id="username" type="text" name="username" placeholder="Username" onChange={updateLoginData} />
            <InputErrorMessage shouldRender={errorElement === 'username'} message={errorMessage} />
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" autoComplete="on" placeholder="Password" onChange={updateLoginData} />
            <InputErrorMessage shouldRender={errorElement === 'password'} message={errorMessage} />
            <input className={styles.LoginSubmitButton}
              type="submit"
              value="Login"
              disabled={loginData.username === '' || loginData.password === ''} />
          </form>
          <div className={styles.PageInfo}>
            You do not have an account? <Link className='textLink' to="/signup">Sign up here</Link>
          </div>
          <div className={styles.PageInfo}>
            <Link to="/account/password/reset">Forgot password?</Link>
          </div>
        </div>

      </div>

      {isLargeScreen && (
        <div className={styles.LoginScreenImage}>
          <img src={LoginImage} alt="Login screen" />
        </div>
      )}
    </div>
  );
};

export default LoginForm;
