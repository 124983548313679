import { ReactComponent as ErrorIcon } from '../icons/Error-Icon.svg';

import styles from './inputErrorMessage.module.css';

const inputErrorMessage = ({ shouldRender, message }) => {
  return (
    <div className={styles.ErrorMessage} style={{
      marginBottom: shouldRender ? 'var(--margin-normal)' : 0,
      marginTop: shouldRender ? '1px' : 0,
    }}>
      {shouldRender && <>
        <ErrorIcon className={styles.ErrorIcon} />
        <div>{message}</div>
      </>}
    </div>
  );
};

export default inputErrorMessage;
