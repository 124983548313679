import { useEffect } from 'react';
import { ReactComponent as SuccessIcon } from '../icons/Check-Symbol.svg';

import moduleStyles from './authentication.module.css';

const SignupSuccessPage = () => {
  useEffect(() => {
    document.title = 'Verify your email address to start streaming on Onda the free live streaming platform for DJs';
  }, []);

  return (
    <div className={moduleStyles.StatusPage}>
      <SuccessIcon className={moduleStyles.SuccessIcon} />
      <h1>Sign Up Success</h1>
      <p>
        Thanks for signing up to onda. We have sent you and email
        to verify your address. Do not forget to check your spam folder.
      </p>
    </div>
  );
};

export default SignupSuccessPage;
