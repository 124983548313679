const config = {
  // === production
  serverUrl: 'https://api.ondaradio.live',
  baseUrl: 'https://ondaradio.live',

  // === development
  // serverUrl: 'http://localhost:8083',
  // baseUrl: 'http://localhost:3000',

  // === mobile testing
  // baseUrl: 'http://192.168.0.158:3000',
  // serverUrl: 'http://192.168.0.158:8083',

  pushNotification: {
    publicKey: 'BIb4xFZGc6aXbaKj424Kw7x8EKKLr2oNDVCckDZ0yYZuR8gtgEQvR08RQeWgAJ1skBw4gWnc_pSU1UkRoUh4G34'
  },
  initialAvatar: {
    colors: ['#FF1F55', '#FF331F', '#A12AFF', '#552AFF', '#FFA605']
  },
  closeStyle: {
    color: 'white'
  },
  auth: {
    google: {
      clientId: '526129285859-qcj4q4inm1itrijssqvrlkhtgce5q1bi.apps.googleusercontent.com'
    },
    credentialsMode: 'include'
  }
};

export default config;
