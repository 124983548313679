import { useSpring, animated } from '@react-spring/web';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './home.module.css';


const WelcomeScreen = () => {
  const [{ left, right }, gradientBlockRightSpring] = useSpring(() => ({
    left: '100%',
    right: '100%',
  }));

  const [{ opacity, scale }, headerSpring] = useSpring(() => ({
    opacity: 0,
    scale: 0.93,
  }));

  useEffect(() => {
    setTimeout(() => {
      gradientBlockRightSpring.start({ left: '42%', right: '42%' });
    }, 200);
    setTimeout(() => {
      headerSpring.start({
        opacity: 1,
        scale: 1,
      });
    }, 360);
  }, []);

  return (
    <div className={[styles.Page, styles.LandingPage].join(' ')}>
      <div className={styles.GradientBorderWrapper}>
        <animated.div className={styles.GradientBlockLeft} style={{ right }} />
        <animated.div className={styles.GradientBlockRight} style={{ left }} />
        <animated.div style={{ opacity }} className={styles.GradientBorder} />
        <animated.h1 style={{ scale, opacity }}> Join the free DJ Livestreaming Platform </animated.h1>
        <animated.h2 style={{ scale, opacity }}> Get onda dancefloor!</animated.h2>
        <Link to="signup">
          <animated.button style={{ scale, opacity }} className={styles.JoinNowButton}>
            Join Now
          </animated.button>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeScreen;
