import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated, config } from '@react-spring/web';

import styles from './dialog.module.css';


const DialogContext = React.createContext();

const DialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState({
    title: '',
    action: {
      confirmText: '',
      callback: () => { }
    },
    content: ''
  });

  const openDialog = (dialogOptions) => {
    setOptions(dialogOptions);
    setIsOpen(true);
  };

  const closeDialog = () => setIsOpen(false);

  useEffect(() => {
    spring.start({
      scale: isOpen ? 1 : 0,
      opacity: isOpen ? 1 : 0
    });
  }, [isOpen]);

  const [{ scale, opacity }, spring] = useSpring(() => ({
    config: config.stiff,
    scale: 0,
    opacity: 0
  }));

  const doAction = (e) => {
    e.stopPropagation();
    options.action.callback(e);
    closeDialog();
  };

  return (
    <DialogContext.Provider value={openDialog}>
      {children}
      <animated.div className={[styles.Dialog, isOpen && styles.Open].join(' ')} style={{ opacity }}>
        <animated.div className={styles.Box} style={{ scale }}>
          <h2>{options.title}</h2>
          <div>
            {options.content}
          </div>
          <div className={styles.ButtonWrapper}>
            <button className="grey" onClick={closeDialog}>Cancel</button>
            <button className="red" aria-label='submit' onClick={doAction}>{options.action.confirmText}</button>
          </div>
        </animated.div>
      </animated.div>
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
export default DialogProvider;
