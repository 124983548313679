import { get } from './userSlice';
import { getLastStreams } from '../icecast/icecastSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './user.module.css';
import StreamHistoryEntry from './StreamHistoryEntry';
import SubscribeButton from '../pushnotification/SubscribeButton';

import { ReactComponent as PlayIcon } from '../icons/Play-Icon_1.svg';
import { ReactComponent as PauseIcon } from '../icons/Pause-Icon_1.svg';
import { ReactComponent as BackIcon } from '../icons/Back-Icon-Left.svg';
import { ReactComponent as ShareIcon } from '../icons/Share-Icon.svg';
import { ReactComponent as EditIcon } from '../icons/Edit-Icon.svg';


import { setCurrentUsername, setPlaying } from '../audio/audioSlice';
import Avatar from 'boring-avatars';
import TopUsersBySubscriptions, { UiModes } from './TopUsersBySubscriptions';
import config from '../config';
import { useMediaQuery } from 'react-responsive';

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUser = useSelector(state => state.user.current);
  const user = useSelector(state => state.user.viewing);
  const lastStreams = useSelector(state => state.icecast.lastStreams);
  const audioState = useSelector(state => state.audio);
  const currentSources = useSelector(state => state.icecast.sources);

  const isLargeScreen = useMediaQuery({ query: '(min-width: 900px)' });

  const { username } = useParams();

  const playAudio = () => {
    dispatch(setCurrentUsername(username));
  };

  const stopAudio = () => {
    dispatch(setPlaying(false));
  };

  const toggleAudio = () => {
    if (audioState.currentUsername !== username) {
      playAudio();
    } else if (audioState.playing) {
      stopAudio();
    } else {
      dispatch(setPlaying(true));
    }
  };

  const selectPlaybackIcon = () => {
    if (audioState?.currentUsername !== username) {
      return (<PlayIcon className={ styles.playIcon } />);
    } else if (audioState?.playing) {
      return (<PauseIcon className={ styles.pauseIcon } />);
    } else {
      return (<PlayIcon className={ styles.playIcon } />);
    }
  };

  const shareUserProfile = () => {
    if (navigator.share) {
      navigator.share({
        title: `${user.username}'s Onda Profile`,
        text: `Check out ${user.username}'s livesets on ondaradio.live`,
        url: `${config.baseUrl}/user/${audioState.currentUsername}`
      });
    }
  };


  useEffect(() => {
    dispatch(get(username))
      .then((r) => {
        if (r.error) {
          console.error('User not found...');
          navigate('/404');
        }
      });
    dispatch(getLastStreams(username));
  }, [username]);

  useEffect(() => {
    document.title = user.username + ' on Onda the free live streaming platform for DJs';
  });

  const renderAvatar = () => {
    if (user?.username?.toLowerCase() === username?.toLowerCase()) {
      if (user.avatar) {
        return (
          <img className={ styles.Avatar }
            src={ user.avatar }
            alt="avatar" />);
      } else {
        return (
          <Avatar
            variant="ring"
            name={ user.uuid }
            colors={ config.initialAvatar.colors }
          />);
      }
    } else {
      return (<></>);
    }
  };

  return (
    <div className={ `${styles.UserProfile} infoPage` }>

      { !isLargeScreen &&
        <div className='actionBar center'>
          <BackIcon className="back action" onClick={ () => navigate(-1) } />
          <div className='actionWithIconAndText center action' onClick={ shareUserProfile } >
            <ShareIcon />
            <div>Share</div>
          </div>
        </div>
      }

      <div className={ styles.ProfileHeaderWrapper }>

        <div className={ styles.AvatarWrapper } style={ {
          background: user?.broadcasting ? 'linear-gradient(360deg, var(--secondary-color), transparent)' : 'var(--background-lighter-color)'
        } }>
          { renderAvatar() }
          { user.broadcasting && <div className={ styles.LiveBox }>Now Live</div> }
        </div>

        <div className={ styles.ProfileHeader }>
          <div>
            <div className={ styles.ProfileUserName }>
              { user?.username }
            </div>
            <div className={ styles.ProfileSubscribers }>
              <div>{ user?.subscriberCount ? user?.subscriberCount : '0' } Subscribers</div>
            </div>
          </div>
          <div className={ styles.ProfileUserActions }>
            { isLargeScreen && <SubscribeButton user={ user } /> }
            { isLargeScreen &&
              <div className='actionWithIconAndText center action' onClick={ shareUserProfile } >
                <ShareIcon />
                <div>Share</div>
              </div> }
            { loggedInUser?.username?.toLowerCase() === username?.toLowerCase() && (
              <Link className="flex row alignCenter" to="/account/edit">
                <EditIcon className={ [styles.Icon, styles.EditIcon].join(' ') } />
                <div className="fontBold fontSizeNormal">Edit</div>
              </Link>
            ) }
          </div>
        </div>

      </div>

      { !isLargeScreen &&
        <>
          <SubscribeButton user={ user } />
          <div className={ styles.ProfileBio }>
            { user?.bio }
          </div>
        </>
      }


      <div className={ styles.ProfileContentWrapper }>
        <div className={ styles.ProfileLeftAside }>

          { user.broadcasting &&
            <div className={ styles.LiveSection }>
              <div className={ styles.LiveTitle }></div>
              { (() => {
                const stream = currentSources?.filter(s => s.user.username === user.username)[0];
                if (!stream) {
                  return (<></>);
                }
                return (
                  <div className={ styles.StreamInfo }>
                    <div className={ styles.ServerNameAndGenre }>
                      <div className={ styles.ServerName }>{ stream.serverName }</div>
                      <div className={ styles.CurrentStreamGenre }>#{ stream.genre }</div>
                    </div>
                    <div className={ styles.TrackWrapper }>
                      <div className={ [styles.PlayIconWrapper, 'action'].join(' ') } onClick={ toggleAudio }>
                        { selectPlaybackIcon() }
                      </div>
                      <div>
                        <label>Current Song</label>
                        <div>{ stream.artist } - { stream.title }</div>
                      </div>
                    </div>

                  </div>
                );
              })() }
            </div>
          }

          <div className={ styles.ProfileLastStreams }>
            <h2>Last Streams</h2>
            { lastStreams?.filter(s => !!s.endTime).length === 0 &&
              <div className={ styles.NoStreams }>
                This user has not streamed any livesets yet.
                You can subscribe so you won't miss when they go live 🎷
              </div> }
            { lastStreams?.filter(s => !!s.endTime && s.tracks.length > 0)
              .reverse()
              .map(stream => (
                <StreamHistoryEntry key={ stream.endTime } stream={ stream } isMe={ username && username === loggedInUser?.username } />
              )) }
          </div>
        </div>
        { isLargeScreen &&
          <div className={ styles.ProfileAside }>
            { user.bio &&
              <div className={ styles.About }>
                <h2>About</h2>
                <div>{ user.bio }</div>
              </div>
            }
            <TopUsersBySubscriptions uiMode={ UiModes.CIRCLE } />
          </div>
        }
      </div>

    </div>
  );
};

export default UserProfile;
