import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CONFIG from '../config';
import { handleHttpError } from '../utils/fetchUtils';


const initialState = {
  allowsNotifications: !!localStorage.getItem('allowsNotifications'),
  subscription: localStorage.getItem('subscription') ? JSON.parse(localStorage.getItem('subscription')) : null,
  subscribedUsers: localStorage.getItem('subscribedUsers') ? JSON.parse(localStorage.getItem('subscribedUsers')) : [],
  subscribedUserObjects: []
};

export const subscribeToUser = createAsyncThunk(
  'pushNotification/subscribe',
  async (uuid) => {
    if (!localStorage.getItem('allowsNotifications')) {
      const pushNotificationPermission = await Notification.requestPermission();
      if (pushNotificationPermission === 'granted') {
        localStorage.setItem('allowsNotifications', true);
        const registration = await navigator.serviceWorker.getRegistration();
        const subscription = await registration?.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(CONFIG.pushNotification.publicKey)
        });
        console.debug('subscription', subscription);
        localStorage.setItem('subscription', JSON.stringify(subscription));
      } else {
        return;
      }
    }
    const subscription = JSON.parse(localStorage.getItem('subscription'));
    if (subscription) {
      return await fetch(`${CONFIG.serverUrl}/pushnotification/subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subscription, uuid })
      })
        .then(handleHttpError);
    }
  }
);

export const unsubscribeFromUser = createAsyncThunk(
  'pushNotification/unsubscribe',
  async (uuid) => {
    const subscription = JSON.parse(localStorage.getItem('subscription'));
    if (subscription) {
      return await fetch(`${CONFIG.serverUrl}/pushnotification/subscription`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subscription, uuid })
      })
        .then(handleHttpError);
    }
  }
);

export const getSubscribedUsers = createAsyncThunk(
  'pushNotification/getSubscribedUsers',
  async (uuids) => {
    const query = uuids.map(uuid => `uuids=${uuid}`).join('&');
    return await fetch(`${CONFIG.serverUrl}/user?${query}`)
      .then(handleHttpError);
  }
);


export const pushNotificationSlice = createSlice({
  name: 'pushNotification',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getSubscribedUsers.fulfilled, (state, action) => {
        state.subscribedUserObjects = action.payload;
      })
      .addCase(subscribeToUser.fulfilled, (state, action) => {
        console.debug('subscribeToUser.fulfilled', action.payload);
        if (action.payload.status) {
          localStorage.setItem('subscribedUsers', JSON.stringify(
            [...(JSON.parse(localStorage.getItem('subscribedUsers')) || []), action.payload.uuid]
          ));
          state.subscribedUsers = [...state.subscribedUsers, action.payload.uuid];
        }
      })
      .addCase(unsubscribeFromUser.fulfilled, (state, action) => {
        console.debug('unsubscribeFromUser.fulfilled', action.payload);
        if (action.payload.status) {
          localStorage.setItem('subscribedUsers', JSON.stringify(
            JSON.parse(localStorage.getItem('subscribedUsers')).filter(uuid => uuid !== action.payload.uuid)
          ));
          state.subscribedUsers = state.subscribedUsers.filter(uuid => uuid !== action.payload.uuid);
        }
      });
  }
});

const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export default pushNotificationSlice.reducer;
