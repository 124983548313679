import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CONFIG from '../config';
import { handleHttpError } from '../utils/fetchUtils';

const initialState = {
  searchResult: [],
  error: null,
};

export const search = createAsyncThunk(
  'search/user',
  async (q) => {
    console.debug('q: ', q);
    if (q === '') {
      return [];
    }
    const response = await fetch(`${CONFIG.serverUrl}/search/user?q=${q}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(handleHttpError);
    return response;
  }
);

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  extraReducers: builder =>
    builder.addCase(search.fulfilled, (state, action) => {
      console.debug('Searched');
      state.searchResult = action.payload;
    })
      .addCase(search.rejected, (state, action) => {
        console.debug('Failed to search');
        state.error = action.error.message;
      })
});

export default searchSlice.reducer;
