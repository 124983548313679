import { Link, useNavigate } from 'react-router-dom';
import LogoutButton from '../authentication/LogoutButton';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAccount, logout } from '../authentication/authenticationSlice';

import styles from './user.module.css';

import { ReactComponent as EditIcon } from '../icons/Edit-Icon.svg';
import { ReactComponent as DeleteIcon } from '../icons/Delete-Icon.svg';
import { ReactComponent as LogoutIcon } from '../icons/Logout-Icon.svg';
import { ReactComponent as SpeakerIcon } from '../icons/Sound-Icon.svg';
import { ReactComponent as EarIcon } from '../icons/Ear-Icon.svg';
import { ReactComponent as ClockIcon } from '../icons/Clock-Icon.svg';
import { ReactComponent as TrendIcon } from '../icons/Statistics-Icon.svg';
import { ReactComponent as SubscriptionIcon } from '../icons/Subscribe-Icon.svg';

import { useEffect, useState } from 'react';
import Avatar from 'boring-avatars';
import config from '../config';
import { useDialog } from '../utils/DialogContext';
import oAuth2Providers from '../authentication/oauth2Providers';
import ChangePasswordForm from '../authentication/ChangePasswordForm';


const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dialog = useDialog();
  const sources = useSelector(state => state.icecast.sources);

  const currentUser = useSelector(state => state.user.current);
  const myStream = useSelector(state =>
    state.icecast.sources
      .find(s => s.user?.username === currentUser?.username));

  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);


  const openDeleteAccountDialog = () => {
    dialog({
      title: 'Delete Account?',
      content: 'Are you sure you want to delete your account? This action cannot be undone.',
      action: {
        confirmText: 'Delete',
        callback: () => {
          dispatch(deleteAccount())
            .then(() => dispatch(logout()));
          navigate('/');
        }
      }
    });
  };

  // TODO rausziehen
  const renderDuration = () => {
    const seonds = ((new Date()).getTime() - Date.parse(myStream?.startTime)) / 1000;
    const secNum = parseInt(seonds, 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;

    return [hours, minutes]
      .filter((v) => v !== 0)
      .join('h ') + 'm ago';
  };

  useEffect(() => {
    document.title = 'Your Account on Onda';
  }, []);


  return (
    <div className={`${styles.AccountPage} infoPage`}>
      <div className={[styles.UserInfo, 'flex row spaceBetween alignCenter'].join(' ')}>
        <div className="flex row alignCenter">
          <div className={styles.AvatarWrapper}>
            {currentUser
              ? currentUser.avatar
                ? <img className={styles.PreviewAvatar} alt="avatar" src={currentUser?.avatar} />
                : <Avatar
                  variant="ring"
                  name={currentUser?.uuid}
                  colors={config.initialAvatar.colors}
                />
              : <></>
            }
          </div>
          <div>
            <div className={['fontBold', 'fontSizeNormal', styles.AccountUserName].join(' ')}>{currentUser?.username}</div>
            <Link className="fontMedium fontSizeNormal" to={`/user/${currentUser?.username}`}>ShowProfile</Link>
          </div>
        </div>
        <Link to="edit" className="flex row alignCenter">
          <EditIcon className={[styles.Icon, styles.EditIcon].join(' ')} />
          <div className="fontMedium fontSizeNormal">Edit</div>
        </Link>
      </div>
      <div className={[styles.ActionLinkWrapper, 'action'].join(' ')}>
        <LogoutIcon />
        <div className={styles.ActionLink}>
          <LogoutButton className={styles.ActionLink} action={() => navigate('/', { replace: true })} />
        </div>
      </div>
      {currentUser?.oauth2Provider === oAuth2Providers.ONDA && (
        <div className={[styles.ActionLinkWrapper, 'action'].join(' ')} onClick={() => setIsChangePasswordOpen(!isChangePasswordOpen)}>
          <EditIcon className={[styles.Icon, styles.EditIcon].join(' ')} />
          <div className={styles.ActionLink}>Change Password</div>
        </div>
      )}
      {(currentUser?.oauth2Provider === oAuth2Providers.ONDA && isChangePasswordOpen) && (
        <ChangePasswordForm onSuccess={() => setIsChangePasswordOpen(false)} />
      )}
      <div onClick={openDeleteAccountDialog} className={[styles.ActionLinkWrapper, 'action'].join(' ')}>
        <DeleteIcon />
        <div className={styles.ActionLink}>Delete account</div>
      </div>
      <div onClick={() => navigate('subscriptions')} className={[styles.ActionLinkWrapper, 'action'].join(' ')}>
        <SubscriptionIcon />
        <div className={styles.ActionLink}>My Subscriptions</div>
      </div>
      {(sources?.find(s => s.user.username === currentUser?.username)) &&
        <div>
          <div className={[styles.CurrentLivestreamTitle, 'flex row alignCenter'].join(' ')}>
            <div>Your Current Livestream</div>
            <SpeakerIcon className={styles.Icon} />
          </div>
          <div className={styles.CurrentLivestream}>
            <div className={styles.StreamTitle}>{myStream?.serverName}</div>
            <div className={styles.TrackInfo}>
              {myStream?.title} – {myStream?.artist}
            </div>
            <div className={styles.Genre}>#{myStream?.genre}</div>
            <div className={styles.Statistics}>
              <div>
                <EarIcon />
                <div>{myStream?.listenerCount} Live Listeners</div>
              </div>
              <div>
                <ClockIcon />
                <div>{renderDuration()}</div>
              </div>
              <div>
                <TrendIcon />
                <div>{myStream?.maxListenerCount} Peak Listeners</div>
              </div>
            </div>
          </div>
        </div>
      }
      <button className={styles.CredentialsButton}
        onClick={() => navigate('streamdata', { replace: false })}>
        Start Stream
      </button>
    </div>
  );
};

export default Account;
