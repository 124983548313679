import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUsername } from '../audio/audioSlice';
import { Link } from 'react-router-dom';

import styles from './icecast.module.css';

import { ReactComponent as EarIcon } from '../icons/Ear-Icon.svg';
import Avatar from 'boring-avatars';
import config from '../config';
import { useSpring, animated, config as springConfig } from '@react-spring/web';
import { useMediaQuery } from 'react-responsive';

const Broadcast = ({ source }) => {
  const dispatch = useDispatch();
  const currentlyPlayedUsername = useSelector(state => state.audio.currentUsername);
  const isAtLeastTablet = useMediaQuery({ query: '(min-width: 750px)' });

  const playAudio = () => {
    dispatch(setCurrentUsername(source.user.username));
  };


  const [{ activeBackground }, spring] = useSpring(() => ({
    config: {
      duration: 3000,
    },
    loop: true,
    from: {
      activeBackground: getGradient(0),
    },
    to: [
      { activeBackground: getGradient(180) },
      { activeBackground: getGradient(360) },
    ]
  }));

  return (
    <div className={styles.Broadcast} onClick={playAudio}>
      <animated.div className={styles.AvatarWrapper} style={{
        background: currentlyPlayedUsername === source.user.username
          ? activeBackground
          : 'var(--background-lighter-color)'
      }}>
        {source?.user?.avatar
          ? <img src={source?.user?.avatar} alt="avatar" />
          : <Avatar
            variant="ring"
            name={source?.user?.uuid}
            colors={config.initialAvatar.colors}
          />
        }
      </animated.div>
      <div className={styles.Info}>
        <div className={styles.Description}>
          {!isAtLeastTablet && <>
            <div className={styles.ServerName}>{source.serverName}</div>
            <div className={styles.Genre}>#{source.genre}</div>
            <Link className={styles.Username} to={`/user/${source.user.username}`}>
              {source.user.username}
            </Link>
          </>}
          {isAtLeastTablet && <>
            <Link className={styles.Username} to={`/user/${source.user.username}`}>
              {source.user.username}
            </Link>
            <div className={styles.ServerName}>{source.serverName}</div>
            <div className={styles.Genre}>#{source.genre}</div>
          </>}
        </div>
        {!isAtLeastTablet &&
          <div className={styles.Listeners}>
            <div>
              <EarIcon />
              <div>{source.listenerCount}</div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

const getGradient = (progress) => {
  return `linear-gradient(${progress}deg, #552AFF, transparent)`;
};

export default Broadcast;
