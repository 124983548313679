import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import BroadcastList from '../icecast/BroadcastList';

import { ParallaxProvider } from 'react-scroll-parallax';
import styles from './home.module.css';
import WelcomeScreen from './WelcomeScreen';
import InfoWithImageScreen from './InfoWithImageScreen';
import TopUsersBySubscriptions from '../user/TopUsersBySubscriptions';
import YellowBackgroundSection from './YellowBackgroundSection';
import NotLoggedInNoBroadcast from './NotLoggedInNoBroadcast';
import Footer from './Footer';
import LoggedInWelcome from './LoggedInWelcome';
import NoLivestreamsLoggedIn from './NoLivestreamsLoggedIn';

const Home = () => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const sources = useSelector(state => state.icecast.sources);
  const sourcesInitialized = useSelector(state => state.icecast.sourcesInitialized);

  useEffect(() => {
    document.title = 'Onda Radio - The free live streaming platform for DJs';
  });

  return (
    <div className={styles.Home}>
      <ParallaxProvider>
        {false ?
          <div className={styles.Loading} />
          : <>
            {isAuthenticated && <LoggedInWelcome />}
            {!isAuthenticated && <WelcomeScreen />}
            {(isAuthenticated && sources.length < 1) && <NoLivestreamsLoggedIn />}
            {sources.length > 0 && <BroadcastList />}
            <InfoWithImageScreen />
            <YellowBackgroundSection />
            <div className={styles.TopUsers}>
              <TopUsersBySubscriptions />
            </div>
            {(sources.length < 1 && !isAuthenticated) &&
              <NotLoggedInNoBroadcast />}
            <Footer />
          </>
        }
      </ParallaxProvider>
    </div>
  );
};

export default Home;
