import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import config from '../config';

import { ReactComponent as SuccessIcon } from '../icons/Check-Symbol.svg';
import { ReactComponent as ErrorIcon } from '../icons/Error-Icon.svg';

import styles from './authentication.module.css';

const VerifyAccountView = () => {
  const location = useLocation();

  const [verificationSuccess, setVerificationSuccess] = useState(false);

  useEffect(() => {
    const token = location.pathname.split('/')[3];
    fetch(`${config.serverUrl}/account/verify/${token}`, {
      method: 'POST',
    }).then(r => {
      if (r.ok) {
        setVerificationSuccess(true);
      } else {
        console.debug('Invalid verification token, or already verified', r);
      }
    });
  }, []);

  useEffect(() => {
    document.title = 'Account verification';
  }, []);

  return (
    <div className={styles.StatusPage}>
      {verificationSuccess ? (
        <div>
          <SuccessIcon className={styles.SuccessIcon} />
          <h1>Your account is verified.</h1>
          <p>Thanks for verifying your email address. Your account has been activated and you can now <Link to="/login"> login</Link> here. Welcome on board.</p>
        </div>
      ) : (
        <div>
          <ErrorIcon className={styles.ErrorIcon} />
          <h1>Verification Failed</h1>
          <p>
            Either you already verified your email adress or the token is invalid. Try to sign in or
            click the link in your email again. Do not forget to check your spam folder.
          </p>
          <p className={styles.Help}>Need help? Contact us here.</p>
        </div>
      )}
    </div>
  );
};

export default VerifyAccountView;
