import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVolume } from './audioSlice';

import { ReactComponent as SpeakerIcon } from '../icons/Sound-Icon.svg';

import styles from './audio.module.css';

const VolumeControl = () => {
  const dispatch = useDispatch();
  const volumeSliderRef = useRef();

  const [muted, setMuted] = useState(false);

  const handleMuteButtonClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (volumeSliderRef.current.disabled) {
      dispatch(setVolume(volumeSliderRef.current.valueAsNumber));
      setMuted(false);
    } else {
      setMuted(true);
      dispatch(setVolume(0));
    }
    volumeSliderRef.current.toggleAttribute('disabled');
  };

  return (
    <div className={styles.Volume}>
      <div className={styles.VolumeIcon} onClick={handleMuteButtonClicked}>
        <SpeakerIcon />
      </div>
      <input
        ref={volumeSliderRef}
        className={styles.VolumeSlider}
        type="range"
        min={0}
        max={1}
        step={0.02}
        onChange={e => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(setVolume(e.target.valueAsNumber));
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </div>
  );
};

export default VolumeControl;
