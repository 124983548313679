import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelf } from './userSlice';

const LoggedInUserInitializer = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);

  useEffect(() => {
    console.debug('UserInitializer');
    if (!isAuthenticated) {
      return;
    }
    dispatch(getSelf());
  }, [isAuthenticated]);

  return null;
};

export default LoggedInUserInitializer;
