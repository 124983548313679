import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../config';
import CONFIG from '../config';
import { handleHttpError } from '../utils/fetchUtils';
import { parseJwt } from '../utils/jwtUtils';


const initialState = {
  // Rename to loggedInUser
  current: null,
  error: null,
  viewing: {},
  topUsersBySubscribers: [],
};

export const getSelf = createAsyncThunk(
  'user/self',
  async (payload, thunkApi) => {
    console.debug('Action: getSelf');
    const response = await fetch(`${CONFIG.serverUrl}/user/self`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: config.auth.credentialsMode
    })
      .then(handleHttpError);
    console.debug('getSelf response: ', response);
    return response;
  }
);

export const get = createAsyncThunk(
  'user/get',
  async (username) => {
    const response = await fetch(`${CONFIG.serverUrl}/user/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(handleHttpError);
    return response;
  }
);

export const getTopUsersBySubscribers = createAsyncThunk(
  'user/top/subscribers',
  async () => {
    const response = await fetch(`${CONFIG.serverUrl}/user/top/subscribers?limit=4`)
      .then(handleHttpError);
    return response;
  }
);

export const updateProfile = createAsyncThunk(
  'user/update/profile',
  async (payload) => {
    const response = await fetch(`${CONFIG.serverUrl}/account/edit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: config.auth.credentialsMode,
      body: JSON.stringify(payload),
    })
      .then(handleHttpError);
    return response;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: builder =>
    builder.addCase(getSelf.fulfilled, (state, action) => {
      console.debug('Got self');
      state.current = action.payload;
    })
      .addCase(getSelf.rejected, (state, action) => {
        console.debug('Failed to get self');
        state.error = action.error.message;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        console.debug('Updated profile:', action.payload);
        state.current = action.payload;
      })
      .addCase(get.fulfilled, (state, action) => {
        console.debug('Got user');
        state.viewing = action.payload;
      })
      .addCase(getTopUsersBySubscribers.fulfilled, (state, action) => {
        console.debug('Got top users by subscribers');
        state.topUsersBySubscribers = action.payload;
      })
});


export const checkIfGoogleUserExists = async credential =>
  fetch(`${config.serverUrl}/user/exists/subject/${parseJwt(credential).sub}`)
    .then(handleHttpError)
    .then(json => json.exists);


export default userSlice.reducer;
