import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStreamData } from './icecastSlice';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as QuestionIcon } from '../icons/Question-Icon.svg';
import image from './images/streamData.webp';
import styles from './icecast.module.css';

import { ReactComponent as StreamTitle } from '../titles/Stream.svg';
import { ReactComponent as BackIcon } from '../icons/Back-Icon-Left.svg';
import { ToastTypes, useToast } from '../toast/ToastContext';
import { useMediaQuery } from 'react-responsive';


const StreamData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openToast = useToast();
  const streamData = useSelector(state => state.icecast.streamData);
  const isWideScreen = useMediaQuery({ query: '(min-width: 1000px)' });

  const copyToClipboard = (attribute) => {
    navigator.clipboard.writeText(streamData[attribute])
      .then(() => openToast({
        type: ToastTypes.SUCCESS,
        title: 'Copied!',
        message: `Copied ${attribute} to clipboard`
      }));
  };

  useEffect(() => {
    dispatch(getStreamData());
  }, []);

  useEffect(() => {
    document.title = 'Your stream credentials for streaming your dj sets live on onda';
  }, []);

  return (
    <div className={`infoPage ${styles.StreamData}`}>
      <BackIcon className={`back action ${styles.BackIcon}`} onClick={() => navigate(-1)} />
      <div className={styles.StreamDataContentWrapper}>
        <div className={styles.StreamDataContent}>
          <div class="pageTitleText">Start your own</div>
          <div className='pageTitleWrapper'>
            <StreamTitle className={`pageTitle withText ${styles.streamSvg}`} />
          </div>
          <div className={styles.StartStreamDescription}>
            Enter this userdata into your broadcasting software
          </div>
          <label> Host / Address </label>
          <div className={styles.InputWrapper}>
            <input type="text" value={streamData?.host} readOnly></input>
            <div class={[styles.Copy, 'action'].join(' ')} onClick={() => copyToClipboard('host')}>copy</div>
          </div>
          <label> Port </label>
          <div className={styles.InputWrapper}>
            <input type="text" value={streamData?.port} readOnly></input>
            <div class={[styles.Copy, 'action'].join(' ')} onClick={() => copyToClipboard('port')}>copy</div>
          </div>
          <label> Mount path </label>
          <div className={styles.InputWrapper}>
            <input type="text" value={streamData?.mountPath} readOnly></input>
            <div class={[styles.Copy, 'action'].join(' ')} onClick={() => copyToClipboard('mountPath')}>copy</div>
          </div>
          <label> Password </label>
          <div className={styles.InputWrapper}>
            <input type="text" value={streamData?.broadcastPassword} readOnly></input>
            <div class={[styles.Copy, 'action'].join(' ')} onClick={() => copyToClipboard('broadcastPassword')}>copy</div>
          </div>
          <div className={styles.StartStreamHelpBox}>
            <QuestionIcon />
            <div>
              Need help with configuring your stream in your broadcasting software?
              Read here <Link to="/howto"> "How to"</Link> set up your DJ software.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.StreamDataImageWrapper}>
        {isWideScreen && <img src={image} alt="Stream data" />}
      </div>
    </div>
  );
};

export default StreamData;
