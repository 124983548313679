import { useState } from 'react';
import { useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useEffect } from 'react';

import { ReactComponent as ArrowIcon } from '../icons/Back-Icon.svg';

import styles from './FAQEntry.module.css';

const FAQEntry = ({ entry }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const answerRef = useRef(null);

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const [{ height, rotate }, expandSpring] = useSpring(() => ({
        height: '0px',
        rotate: '0deg'
    }));

    useEffect(() => {
        expandSpring.start({
            height: isExpanded
                ? `${answerRef.current.scrollHeight}px`
                : '0px',
            rotate: isExpanded ? '-180deg' : '0deg'
        });
    }, [isExpanded]);

    return (
        <div className={styles.FAQEntry} onClick={toggleIsExpanded}>
            <div className={styles.QuestionWrapper}>
                <h3>{entry.question}</h3>
                <animated.div className={styles.CloseIcon} style={{rotate}}>
                    <ArrowIcon />
                </animated.div>
            </div>
            <animated.p className={styles.Answer}
                ref={answerRef}
                style={{ height }}>
                <div>{entry.answer}</div>
            </animated.p>
        </div>
    );
};

export default FAQEntry;
