import { Link } from 'react-router-dom';
import styles from './NoLivestreamsLoggedIn.module.css';

const NoLivestreamsLoggedIn = () => {
    return (
        <div className={styles.NoLivestreamsLoggedIn}>
            <div className={styles.GradientBlock} />
            <div className={styles.Wrapper}>
                <h1>There are currently no livestreams</h1>
                <p>
                    Try again later
                </p>
                <div className={styles.FormDivider}>
                    <div>Or</div>
                </div>
                <button className={styles.StartStreamButton}>
                    <Link to="/account/streamdata">
                        Start Livestream
                    </Link>
                </button>
            </div>
        </div>
    );
};

export default NoLivestreamsLoggedIn;
