import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSpring, animated, config } from '@react-spring/web';

import styles from './audio.module.css';
import { setPlaying } from './audioSlice';

import appConfig from '../config';

import { ReactComponent as PlayIcon } from '../icons/Play-Icon_1.svg';
import { ReactComponent as PauseIcon } from '../icons/Pause-Icon_1.svg';


import Avatar from 'boring-avatars';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import VolumeControl from './VolumeControl';

const AudioPlayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sources = useSelector(state => state.icecast.sources);
  const audioState = useSelector(state => state.audio);
  const playing = useSelector(state => state.audio.playing);
  const volume = useSelector(state => state.audio.volume);

  const [source, setSource] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sourcePropertiesToUpdate, setSourcePropertiesToUpdate] = useState(null);

  const isLargeScreen = useMediaQuery({ query: '(min-width: 900px)' });

  const audioElement = useRef(null);
  const playerElement = useRef(null);
  const profilePictureWrapperElement = useRef(null);

  const [{ y, overflow, scale }, api] = useSpring(() => ({
    config: {
      ...config.stiff,
      bounce: 0,
    },
    y: '100%',
    overflow: 'hidden',
    scale: 1,
  }));

  useEffect(() => {
    if (isOpen) {
      api.start({ y: '0%' });
    } else {
      api.start({ y: '100%' });
    }
  }, [isOpen]);


  useEffect(() => {
    setSource(sources.find(source => source.user.username === audioState.currentUsername));
    if (!source) {
      return;
    } else if (audioState.playing) {
      audioElement.current.setAttribute('src', source.listenUrl);
      audioElement.current.play();
    } else {
      // audioElement.current.setAttribute('src', '');
      audioElement.current.pause();
      // setTimeout(() => audioElement.current.load());
    }
  }, [playing]);

  useEffect(() => {
    audioElement.current.volume = volume;
  }, [volume]);

  const togglePlay = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setPlaying(!audioState.playing));
  };


  useEffect(() => {
    const currentSource = sources.find(source => source.user.username === audioState.currentUsername);
    if (!currentSource) {
      setIsOpen(false);
      return;
    }
    setIsOpen(true);
    setSourcePropertiesToUpdate((({ listenUrl, ...o }) => o)(currentSource));
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: currentSource.artist + ' - ' + currentSource.title,
        artist: currentSource.user.username,
        album: currentSource.user.username,
        artwork: [
          {
            src: currentSource.user.avatar || URL.createObjectURL(new Blob(
              [profilePictureWrapperElement?.current?.querySelector('svg').outerHTML],
              { type: 'image/svg+xml' })),
            sizes: '256x256',
            type: currentSource.user.avatar ? 'image/png' : 'image/svg+xml'
          },
        ]
      });
    }
  }, [sources, source]);

  useEffect(() => {
    if (!('mediaSession' in navigator)) {
      return;
    }
    navigator.mediaSession.setActionHandler('play', () => {
      dispatch(setPlaying(true));
    });
    navigator.mediaSession.setActionHandler('pause', () => {
      dispatch(setPlaying(false));
    });
  }, []);

  const openLiveSet = () => {
    api.start({ from: { scale: 1.03 }, to: { scale: 1 }, config: config.stiff });
    navigate(`/user/${audioState.currentUsername}/liveset/${source.id}`);
  };

  return (
    <animated.div ref={playerElement}
      className={`${styles.AudioPlayer} ${!source && styles.NoSource}`}
      style={{ y, overflow, scale }}>
      <div className={styles.MiniPlayer} onClick={openLiveSet}>
        <div className={styles.DescriptionPreview}>
          {source && <>
            <div className={styles.ProfilePictureWrapper} ref={profilePictureWrapperElement}>
              {
                sourcePropertiesToUpdate?.user?.avatar
                  ? <img className={styles.ProfilePicture}
                    src={sourcePropertiesToUpdate?.user?.avatar} alt="avatar" width="100"
                  />
                  : <Avatar
                    variant="ring"
                    name={sourcePropertiesToUpdate?.user?.uuid}
                    colors={appConfig.initialAvatar.colors}
                  />
              }
            </div>
            <div className={styles.CurrentlyPlaying}>
              <div>{sourcePropertiesToUpdate?.serverName || source.serverName}</div>
              <div className={styles.Track}>{sourcePropertiesToUpdate?.artist || source.artist} – {sourcePropertiesToUpdate?.title || source.title}</div>
            </div>
          </>}
        </div>
        <div className={styles.PlayerControls}>
          <div className={['action'].join(' ')} onClick={togglePlay}>
            {audioState.playing ? <PauseIcon className={styles.PlayIcon} /> : <PlayIcon className={styles.PlayIcon} />}
          </div>
          {isLargeScreen && <VolumeControl />}
        </div>
        <audio
          ref={audioElement}
          src={source?.listenUrl}
          hidden
          onCanPlay={() => audioElement.current.play()}
          crossOrigin="anonymous"
          volume={audioState.volume}></audio>
      </div>
    </animated.div>
  );
};

export default AudioPlayer;
