import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '../config';
import InputErrorMessage from '../utils/InputErrorMessage';
import { ReactComponent as SuccessIcon } from '../icons/Check-Symbol.svg';
import { ReactComponent as ErrorIcon } from '../icons/Error-Icon.svg';
import styles from './authentication.module.css';


const ResetPasswordForm = () => {
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const [passwordDTO, setPasswordDTO] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [username, setUsername] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const pathToken = location.pathname.split('/')[5];
    setToken(pathToken);
    fetch(`${config.serverUrl}/account/password/reset/token/${pathToken}/valid`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error while checking token validity');
      })
      .then((data) => {
        if (data.valid === true) {
          setIsTokenValid(true);
          setUsername(data.username);
        } else {
          setIsTokenValid(false);
        }
      });
  }, []);

  const handlePasswordChange = ({ target }) => {
    const newPasswords = { ...passwordDTO, [target.name]: target.value, };
    if (error) {
      setError(null);
    }
    setPasswordDTO(newPasswords);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (passwordDTO.newPassword !== passwordDTO.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    fetch(`${config.serverUrl}/account/password/reset/token/${token}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newPassword: passwordDTO.newPassword }),
    })
      .then(async (response) => {
        if (response.ok) {
          return setSuccess(true);
        }
        setSuccess(false);
        setError('Error while resetting password: ' + await response.text());
      });
  };

  return (
    <div className='infoPage'>
      {(isTokenValid === true && !success) && (
        <>
          <h1>
            Hey, {username}!<br></br>
            Choose your new password
          </h1>
          <form className={styles.ResetPasswordForm} onSubmit={handleSubmit}>
            <input type="text" name="user" placeholder="Username" autoComplete='username' hidden value={username} readOnly />
            <label htmlFor="newPassword">New Password</label>
            <input type="password" placeholder="New Password" name="newPassword" autoComplete="new-password" onChange={handlePasswordChange} />
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <input type="password" placeholder="Confirm Password" name="confirmPassword" autoComplete="new-password" onChange={handlePasswordChange} />
            <InputErrorMessage shouldRender={error !== null} message={error} />
            <button type="submit" disabled={error !== null}>Submit Password</button>
          </form>
        </>
      )}
      {success === true && (
        <div className={styles.StatusPage}>
          <SuccessIcon className={styles.SuccessIcon} />
          <h1>Successfully changed password</h1>
          <p>Your password has been changed. You can now use it to <Link to="/login">login</Link>.</p>
        </div>
      )}
      {isTokenValid === false && (
        <div className={styles.StatusPage}>
          <ErrorIcon className={styles.ErrorIcon} />
          <h1>Token is invalid or expired</h1>
          <p>You can request a new token <Link to="/account/password/reset">here</Link>.</p>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;
