import styles from './checkbox.module.css';

const Checkbox = ({ label, isChecked, handleChange, required }) => (
  <label className={styles.Checkbox}>
    <input
      type="checkbox"
      name={label}
      checked={isChecked}
      onChange={handleChange}
      className={styles.CheckboxInput}
    />
    <span className={styles.Checkmark}></span>
    <span className={styles.CheckboxLabel}>{label} </span>
    {required && <span className={styles.Required}> *</span>}
  </label>
);

export default Checkbox;
