import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoggedInUserInitializer from './user/LoggedInUserInitializer';
import IcecastStatusPoller from './icecast/IcecastStatusPoller';
import AudioPlayer from './audio/AudioPlayer';
import Header from './layout/Header';

import './App.css';
import DialogProvider from './utils/DialogContext';
import AnimatedRoutes from './layout/AnimatedRoutes';
import ToastProvider from './toast/ToastContext';
import AskForCookies from './cookie/AskForCookies';

const organizationStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  'name': 'Onda Radio - The free Dj Broadcasting Platform',
  'url': 'https://ondaradio.live',
  'logo': 'https://ondaradio.live/hd_hi.svg'
};


const App = () => {
  return (
    <GoogleOAuthProvider clientId="526129285859-qcj4q4inm1itrijssqvrlkhtgce5q1bi.apps.googleusercontent.com">
      <script type="application/ld+json">{JSON.stringify(organizationStructuredData)}</script>
      <Router className="App">
        <LoggedInUserInitializer />
        <IcecastStatusPoller />
        <DialogProvider>
          <ToastProvider>
            <Header />
            <div className="content">
              <AnimatedRoutes />
            </div>
            <AudioPlayer />
          </ToastProvider>
        </DialogProvider>
        <AskForCookies />
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
