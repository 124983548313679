import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Search from '../search/Search';
import { useEffect, useRef, useState } from 'react';

import { useSpring, animated, config } from '@react-spring/web';

import { ReactComponent as Logo } from '../icons/Logo.svg';
import { ReactComponent as SearchIcon } from '../icons/Search-Icon.svg';
import { ReactComponent as HamburgerIcon } from '../icons/Menu-Icon.svg';
import { ReactComponent as CloseIcon } from '../icons/Exit-Icon.svg';
import { ReactComponent as HomeIcon } from '../icons/Home-Icon.svg';
import { ReactComponent as SignupIcon } from '../icons/Sign-Up-Icon.svg';
import { ReactComponent as LoginIcon } from '../icons/Login-Icon.svg';
import { ReactComponent as HowtoIcon } from '../icons/How-to-Icon.svg';
import { ReactComponent as AccountIcon } from '../icons/Profil-Icon.svg';
import { ReactComponent as SoundIcon } from '../icons/Sound-Icon.svg';
import { ReactComponent as SubscriptionIcon } from '../icons/Subscribe-Icon.svg';

import appConfig from '../config';

import './layout.css';
import { useMediaQuery } from 'react-responsive';
import Avatar from 'boring-avatars';

const Header = () => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const loggedInUser = useSelector(state => state.user.current);
  const location = useLocation();
  const menuLinksRef = useRef(null);
  const expandedMenuLinksRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 900px)' });

  const highlightActiveLink = () => {
    const links = isLargeScreen ? expandedMenuLinksRef.current : menuLinksRef.current;
    [...links.querySelectorAll('a')]
      .forEach(link => {
        link.classList.remove('active');
        if (link.pathname === location.pathname) {
          link.classList.add('active');
        }
      });
  };

  useEffect(() => {
    if (isLargeScreen && isMenuOpen) {
      closeMenu();
    }
  }, [isLargeScreen]);

  useEffect(highlightActiveLink, [location]);

  const toggleSearch = (e) => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => setIsMenuOpen(false);

  useEffect(() => {
    if (isMenuOpen) {
      menuOpacitySpring.start({
        from: {
          inverseOpacity: 0.3,
          translateX: '100%'
        },
        to: {
          inverseOpacity: 1,
          translateX: '0'
        }
      });
    } else {
      menuOpacitySpring.start({
        inverseOpacity: 0,
        translateX: '100%'
      });
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isSearchOpen) {
      searchOpacitySpring.start({
        searchOpacity: 1,
      });
    } else {
      searchOpacitySpring.start({
        searchOpacity: 0,
      });
    }
  }, [isSearchOpen]);

  const [{ inverseOpacity, translateX }, menuOpacitySpring] = useSpring(() => ({
    config: {
      ...config.stiff,
    },
    opacity: 0,
    translateX: '100%'
  }));

  const [{ searchOpacity }, searchOpacitySpring] = useSpring(() => ({
    config: { config: config.stiff },
    searchOpacity: 0
  }));

  useEffect(() => {
    document.body.style.overflow = (isMenuOpen || isSearchOpen) ? 'hidden' : 'auto';
  }, [isMenuOpen, isSearchOpen]);

  // TODO remove duplication
  const renderAvatar = (user) => {
    if (user?.username?.toLowerCase() === user.username?.toLowerCase()) {
      if (user.avatar) {
        return (
          <img className={ 'avatar' }
            src={ user.avatar }
            alt="avatar" />);
      } else {
        return (
          <Avatar
            variant="ring"
            name={ user.uuid }
            colors={ appConfig.initialAvatar.colors }
          />);
      }
    } else {
      return (<></>);
    }
  };

  return (
    <header>
      <div ref={ expandedMenuLinksRef } className='headerExpandedLinks'>
        <Link to="/" aria-label='Back to start'>
          <Logo className={ `logo ${isMenuOpen ? 'hidden' : ''} svg` } />
        </Link>
        { isLargeScreen && (
          <>
            <Link to="/" className='headerLink' aria-label='Back to start'>
              Streams
            </Link>
            <Link to="/account/subscriptions" className='headerLink' aria-label='My Subscriptions'>
              Subscriptions
            </Link>
            { isAuthenticated && (
              <Link className="headerLink" onClick={ closeMenu } to="account/streamdata">
                <div>Broadcast Data</div>
              </Link>
            ) }
            <Link to="/howto" className="headerLink" aria-label='How to'>
              How to
            </Link>
          </>
        ) }
      </div>

      <div className='expandedAccountSignLinks'>
        <animated.div className={ `SearchView ${!isSearchOpen && !isLargeScreen ? 'closed' : ''}` }
          style={ { opacity: isLargeScreen ? 1 : searchOpacity } }>
          <Search closeSearch={ toggleSearch } isOpen={ isSearchOpen } />
        </animated.div>

        { isLargeScreen && !isAuthenticated && (<>
          <Link to='/login'><button className='transparent login'>Login</button></Link>
          <Link to='/signup'><button className='signUp'>Sign up</button></Link>
        </>
        ) }

        { isLargeScreen && isAuthenticated && (<Link to='/account' className='accountLink flex alignCenter fontMedium'>
          <div className='avatarWrapper'>{ loggedInUser && renderAvatar(loggedInUser) }</div>
          <div className='headerUsername'>{ loggedInUser?.username }</div>
        </Link>) }

        { !isLargeScreen && (
          <>
            <SearchIcon onClick={ toggleSearch } className="searchIcon action" />
            { isMenuOpen
              ? <CloseIcon onClick={ toggleMenu } className={ `hamburger ${isMenuOpen && 'open'} svg action` } />
              : <HamburgerIcon onClick={ toggleMenu } className={ `hamburger ${isMenuOpen && 'open'} svg action` } />
            }
          </>
        ) }
      </div>

      { !isLargeScreen && (
        <animated.nav className={ isMenuOpen ? 'open' : 'closed' } style={ { translateX } } ref={ menuLinksRef }>
          <Link className="MenuLinkWrapper" onClick={ closeMenu } to="/">
            <HomeIcon />
            <div>Streams</div>
          </Link>
          {
            isAuthenticated
              ? <>
                <Link className="MenuLinkWrapper" onClick={ closeMenu } to="/account">
                  <AccountIcon />
                  <div>Account</div>
                </Link>
                <Link className="MenuLinkWrapper" onClick={ closeMenu } to="account/streamdata">
                  <SoundIcon />
                  <div>Broadcast Data</div>
                </Link>
              </>
              : <>
                <Link className="MenuLinkWrapper" onClick={ closeMenu } to="/login">
                  <LoginIcon />
                  <div>Login</div>
                </Link>
                <Link className="MenuLinkWrapper" onClick={ closeMenu } to="/signup">
                  <SignupIcon />
                  <div>Signup</div>
                </Link>
              </>
          }
          <Link className="MenuLinkWrapper" onClick={ closeMenu } to="/account/subscriptions">
            <SubscriptionIcon />
            <div>Subscriptions</div>
          </Link>
          <Link className="MenuLinkWrapper" onClick={ closeMenu } to="/howto">
            <HowtoIcon />
            <div>How to</div>
          </Link>
          <Logo className='MenuLogo logo' />
          <div className="MenuFooter">
            <div><Link to="/imprint" onClick={ closeMenu }>Legal</Link></div>
            <div>Contact</div>
            <div className='CopyRight'>© 2022 Onda Radio</div>
          </div>
        </animated.nav>
      ) }

      <animated.div className='NavSkim' style={ { opacity: inverseOpacity } }></animated.div>
    </header >
  );
};

export default Header;
