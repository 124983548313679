import { useSelector } from 'react-redux';
import styles from './LoggedInWelcome.module.css';

const LoggedInWelcome = () => {
    const user = useSelector(state => state.user.current);
    return (
        <div className={styles.LoggedInWelcome}>
            <div className={styles.GradientBlock} />
            <h1>Welcome {user?.username}!</h1>
        </div>
    );
};

export default LoggedInWelcome;
