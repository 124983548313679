import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from '../authentication/authenticationSlice';
import userReducer from '../user/userSlice';
import icecastReducer from '../icecast/icecastSlice';
import pushNotificationReducer from '../pushnotification/pushNotificationSlice';
import audioReducer from '../audio/audioSlice';
import searchReducer from '../search/searchSlice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    user: userReducer,
    icecast: icecastReducer,
    pushNotification: pushNotificationReducer,
    audio: audioReducer,
    search: searchReducer
  },
});
