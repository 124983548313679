import styles from './howto.module.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FAQEntry from './FAQEntry';

import { ReactComponent as TraktorIcon } from './images/traktorIcon.svg';
import { ReactComponent as SeratoIcon } from './images/seratoIcon.svg';
import { ReactComponent as RekordboxIcon } from './images/rekordboxIcon.svg';
import { ReactComponent as VirtualDJIcon } from './images/virtualDJIcon.svg';
import { ReactComponent as ArrowRight } from '../icons/arrowRight.svg';


const Howto = () => {
  useEffect(() => {
    document.title = 'How to start your own stream for free on Onda the free live streaming platform for DJs';
  }, []);

  return (
    <div className={`${styles.HowToPage} infoPage`}>
      <h1>How to Broadcast your DJ Livestream for Free</h1>
      <h2>Choose your DJ Software</h2>
      <div className={styles.SoftwareList}>
        <Link to='traktor'>
          <div className={styles.Software}>
            <div className={styles.SoftwareLeftSide}>
              <TraktorIcon />
              <h3>Traktor</h3>
            </div>
            <ArrowRight className={styles.ArrowRight} />
          </div>
        </Link>
        <Link to='virtualDJ'>
          <div className={styles.Software}>
            <div className={styles.SoftwareLeftSide}>
              <VirtualDJIcon />
              <h3>VirtualDJ</h3>
            </div>
            <ArrowRight className={styles.ArrowRight} />
          </div>
        </Link>
        <Link to='rekordbox'>
          <div className={styles.Software}>
            <div className={styles.SoftwareLeftSide}>
              <RekordboxIcon />
              <h3>Rekordbox</h3>
            </div>
            <ArrowRight className={styles.ArrowRight} />
          </div>
        </Link>
        <Link to='serato'>
          <div className={styles.Software}>
            <div className={styles.SoftwareLeftSide}>
              <SeratoIcon />
              <h3>Serato</h3>
            </div>
            <ArrowRight className={styles.ArrowRight} />
          </div>
        </Link>
      </div>
      <p>
        Here you can find guides how to broadcast your liveset with your DJ Software
        (Traktor, VirtualDJ, Rekorbox, Serato) for free.
      </p>
      <div className={styles.FAQ}>
        <h2>FAQ</h2>
        {faqEntries.map((entry, index) => (
          <FAQEntry key={index} entry={entry} />
        ))}
      </div>
    </div>
  );
};

const faqEntries = [
  {
    question: 'How can I broadcast my DJ Livestream for free?',
    answer: 'To broadcast with Traktor for free on ondaradio, you can follow these steps: \n 1. Go to the website ondaradio.live, look for the section called "How to". \n 2. Follow the instructions provided in that section to set up your broadcast on Traktor. \n 3. In the broadcast settings of Traktor, enter your ondaradio.live stream details. \n 4. Once your settings are correctly configured, your stream will be automatically live on ondaradio and can be listened to by your audience. \n Note: It is important that you have a stable internet connection and the required software and hardware to broadcast. \n Also, it\'s always good to check if there is any update on the website or the software and to make sure that you have the latest version.',
  },
  {
    question: 'Is it really free?',
    answer: 'Yes, it is truly free to broadcast on ondaradio.live. There are no hidden costs or fees associated with using the platform. The website is designed to make it easy and accessible for DJs and artists to share their music without having to pay like they would on other platforms like Mixcloud. Additionally, the website has plans to enable DJs to earn money off of their ondaradio-streams.',
  },
  {
   question: 'Can I earn money with Onda?',
   answer: 'Currently, there are no options to earn money as a DJ on ondaradio. However, the platform has plans to introduce various ways for DJs to monetize their streams in the future. These may include options such as selling tickets for paid streams, accepting donations, subscriptions, or selling custom emoji packs. It is important to keep an eye  on the updates on the website and to stay informed about the platform\'s plans and developments in this regard. As the platform continues to evolve and improve, it is possible that new ways to earn money as a DJ on ondaradio will become available.',
  },
  {
   question: 'What about licenses and who is responsible for copyrights?',
   answer: 'The responsibility for obtaining licenses and ensuring compliance with copyright laws falls entirely on the DJ or broadcaster. Streaming services, such as ondaradio, act solely as a platform and do not take on any responsibility for obtaining licenses or ensuring compliance with copyright laws. It is the DJ or broadcaster\'s responsibility to ensure that they have obtained the necessary licenses and permissions for the copyrighted content they are streaming. This includes obtaining a sync license from the copyright holder or a performance license from a performing rights organization before streaming copyrighted content on any platform. It\'s important to be aware of the copyright laws of your country and the rights of the copyright holders and to be aware of the terms of service of the platform you\'re using for streaming. Streaming services may have their own terms of service and copyright policies, but it\'s the DJ\'s or broadcaster\'s responsibility to make sure that they are compliant with the copyright laws.',
  },
  {
   question: 'How to stream to multiple platforms at once?',
   answer: 'To stream on ondaradio and other platforms at once, you can use a software like Open Broadcasting Software (OBS) to set up and configure multiple "scenes" for each platform.\n Here are the steps to stream on multiple platforms using OBS: \n 1. Download and install OBS on your computer. \n 2. Set up your scenes, each representing a different platform you want to stream to, including ondaradio. \n 3. Go to the settings for each platform and find the stream key (also called "streaming key" or "server URL"). \n 4. Enter the stream key for each platform in the corresponding scene settings in OBS. \n 5. Go to ondaradio.live and get your credentials, and add the credentials to the OBS settings of the ondaradio scene. \n 6. Start your live stream on OBS, and switch between scenes as needed to broadcast to all platforms simultaneously. \n \n It\'s important to note that each platform may have different settings and requirements, so you should check the documentation for each platform to ensure that your stream is configured correctly. Also, it\'s always good to check if there is any update on the website or the software and to make sure that you have the latest version. Additionally, having a stable internet connection is important as well as a good hardware to achieve a smooth streaming experience.',
  },
  {
   question: 'Why is my broadcast not working?',
   answer: 'The broadcast may not be working due to a variety of reasons, including incorrect login credentials (e.g. password or host/address), an unstable internet connection, or an incorrect mount path. It is important to double-check all of these settings and ensure that they are entered correctly. Additionally, ensure that the slash in the mount path is present, but if it still not working, try removing it.',
  },
];

export default Howto;
