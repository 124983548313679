import Avatar from 'boring-avatars';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import config from '../config';
import { getTopUsersBySubscribers } from './userSlice';

import styles from './topUsersBySubscriptions.module.css';
import SubscribeButton from '../pushnotification/SubscribeButton';

const TopUsersBySubscriptions = ({ uiMode }) => {
  const dispatch = useDispatch();
  const topUsersBySubscribers = useSelector(state => state.user.topUsersBySubscribers);

  useEffect(() => {
    dispatch(getTopUsersBySubscribers());
  }, []);

  return (
    <div className={ uiMode === UiModes.CIRCLE ? styles.Circle : '' }>
      <h2 className={ [styles.Title].join(' ') }>Discover Top Streamers</h2>
      <div className={ styles.UserList }>
        { topUsersBySubscribers?.map(user => (
          <Link key={ user.username } to={ `/user/${user.username}` }>
            <div className={styles.LeftWrapper}>
              <div className={ styles.TopListenerImageWrapper }>
                { user.avatar ? (
                  <img className={ styles.TopListenerImage } src={ user.avatar } alt='avatar' />
                ) : (
                  <Avatar
                    className='avatar'
                    variant="ring"
                    name={ user.uuid }
                    colors={ config.initialAvatar.colors }
                  />
                ) }
                <div className={ styles.UserName }>{ user.username }</div>
              </div>
              { uiMode === UiModes.CIRCLE && <div className={ styles.CircleUsername }>{ user.username }</div> }
            </div>
            { uiMode === UiModes.CIRCLE && <SubscribeButton color='grey' user={ user } /> }
          </Link>
        )) }
      </div>
    </div>
  );
};

export const UiModes = {
  SQUARE: 'square',
  CIRCLE: 'circle',
};

export default TopUsersBySubscriptions;
