import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from '@react-spring/web';
import { deleteLiveStream } from '../icecast/icecastSlice';
import styles from './user.module.css';

import { ReactComponent as DurationIcon} from '../icons/Clock-Icon.svg';
import { ReactComponent as DeleteIcon} from '../icons/Delete-Icon.svg';
import { useDialog } from '../utils/DialogContext';
import { ToastTypes, useToast } from '../toast/ToastContext';


const StreamHistoryEntry = ({ stream, isMe }) => {
  const dispatch = useDispatch();

  const trackListElement = useRef(null);

  const [isTrackListExpanded, setIsTrackListExpanded] = useState(false);
  const dialog = useDialog();
  const openToast = useToast();

  const toggleIsTrackListExpanded = () => {
    setIsTrackListExpanded(!isTrackListExpanded);
  };

  const renderDate = () => {
    const date = new Date(stream['stream_start_iso8601']);
    return (
      <div className={styles.Date}>
        {date.toLocaleDateString('default')}
      </div>
    );
  };

  const calculateLenth = () => {
    const seconds = Math.round((stream.endTime - Date.parse(stream['stream_start_iso8601'])) / 1000);
    return `${toHHMM(seconds)}`;
  };

  const toHHMM = (secs) => {
    const secNum = parseInt(secs, 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;

    return [hours, minutes]
        .filter((v) => v !== 0)
        .join('h ') + 'm';
};


  const [{ height }, trackListSpring] = useSpring(() => ({
    height: '0px'
  }));

  useEffect(() => {
    trackListSpring.start({
      height: isTrackListExpanded
        ? `${trackListElement.current.scrollHeight}px`
        : '0px'
    });
  }, [isTrackListExpanded]);


  const deleteLiveStreamAction = (e) => {
    e.stopPropagation();
    dispatch(deleteLiveStream(stream.endTime))
      .then(() => openToast({
        title: 'Stream deleted!',
        message: 'The stream has been successfully deleted.',
        type: ToastTypes.SUCCESS
      }));
  };

  const openDeletLiveStreamDialogHandler = e => {
    e.stopPropagation();
    dialog({
      title: `Delete Live Stream?`,
      action: {
        confirmText: 'Delete',
        callback: deleteLiveStreamAction
      },
      content: `Are you sure you want to delete this live stream? You can not restore them later.`
    });
  };

  return (
    <div onClick={toggleIsTrackListExpanded} className={[styles.StreamHistoryEntry, 'action'].join(' ')}>
      <div className={styles.LastStreamPreview}>
        <div>
          <div className={styles.Title}>
            {stream['server_name']}
          </div>
          <div className={styles.Genre}>
            #{stream.genre}
          </div>
          <div className={styles.Duration}>
              <DurationIcon />
            <div>
              {calculateLenth()}
            </div>
          </div>
        </div>
        <div className={styles.LastStreamPreviewRight}>
          {renderDate()}
          {isMe && (
            <div className={styles.DeleteStream} onClick={openDeletLiveStreamDialogHandler}>
              <DeleteIcon />
              <div>delete</div>
            </div>
          )}
          <div>
            {isTrackListExpanded ? 'show less' : 'show more'}
          </div>
        </div>
      </div>
      <animated.div ref={trackListElement} className={styles.TrackList} style={{ height }}>
        <div className={styles.TrackListTitle}>Tracklist</div>
        {stream.tracks.map((t, i) => (
          <div key={i} className={styles.Track}>
            <div className={styles.TrackIndex}>{i + 1}</div>
            <div>
              <div className={styles.TrackTitle}>{t.title}</div>
              <div className={styles.TrackArtist}>{t.artist || '-'}</div>
            </div>
          </div>
        ))}
      </animated.div>
    </div>
  );
};

export default StreamHistoryEntry;
