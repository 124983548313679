import ValidationError from './validationError';

const validateUsername = ({username}) => {
  console.debug('validateUsername', username);
  if (!username) {
    throw new ValidationError('Username required', 'username');
  } else if (username.length < 3) {
    throw new ValidationError('Username must have more than three characters', 'username');
  }
  // TODO optional check if username is already taken
  console.debug('Username is ok:', username);
};

const validatePassword = ({password, confirmPassword}) => {
  console.debug('validatePassword');
  if (!password) {
    throw new ValidationError('Password required', 'password');
  } else if (!confirmPassword) {
    throw new ValidationError('Password is not confirmed', 'confirmPassword');
  } else if (password !== confirmPassword) {
    throw new ValidationError('Passwords do not match', 'confirmPassword');
  } else if (password.length < 3) {
    throw new ValidationError('Password most have more than three characters', 'password');
  } else if (password.length > 20) {
    throw new ValidationError('Password most have less than twenty characters', 'password');
  }
  console.debug('Password is ok');
};

const validateEmail = ({email}) => {
  console.debug('validateEmail');
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    throw new ValidationError('Email required', 'email');
  } else if (!emailRegex.test(email)) {
    throw new ValidationError('Email is not valid', 'email');
  }
  console.debug('Email is ok');
};

const validators = [
  validateUsername,
  validatePassword,
  validateEmail
];

export default validators;
