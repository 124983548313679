import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateSources } from './icecastSlice';

const IcecastStatusPoller = () => {
  const dispatch = useDispatch();

  const pollMetadata = () => {
    console.debug('pollMetadata');
    dispatch(updateSources());
    const interval = setInterval(() => dispatch(updateSources()), 10000);
    return () => clearInterval(interval);
  };

  useEffect(pollMetadata, [dispatch]);

  return null;
};

export default IcecastStatusPoller;
